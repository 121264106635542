import React, { useContext, useReducer, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { MdLibraryAdd, MdClose, MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { getError, getStatus } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import _banners  from '../../services/banner.service';
import { SCOPES } from '../../permission-utils/scopes.js';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_BANNER_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_BANNER_SUCCESS':
		return { ...state, banner: action.payload, loading: false };
	case 'FETCH_BANNER_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditBanner() {
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;

	const [{ loading, error, banner }, dispatch] = useReducer(reducer, {
		banner: {},
		loading: true,
		error: '',
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [name, setName] = useState();
	const [image, setImage] = useState();
	const [isActive, setIsActive] = useState();
	const [errors, setErrors]=useState();

	//#region REQUESTS
	const fetchData = async () => {
		dispatch({ type: 'FETCH_BANNER_REQUEST' });
		try {
			const result = await _banners.get(id);
			dispatch({ type: 'FETCH_BANNER_SUCCESS', payload: result.data });
			setName(result.data.name);
			setImage(result.data.image);
			setIsActive(result.data.isActive);
		} catch (error) {
			dispatch({ type: 'FETCH_BANNER_FAIL', payload: error.message });
			console.error(error);
			toast.error(getError(error));
		}
	};
	const validateInputs = () => {
		const errors = {};
		if (!name) {
			errors.name = 'Debe escribir un nombre';
		}
		if (!image) {
			errors.image = 'Debe agregar un archivo de imagen';
		}
		if (image && !['jpg', 'jpeg', 'png'].includes(image.split('.').pop().toLowerCase())) {
			errors.image = 'La imagen debe tener una extensión jpg, jpeg o png';
		}
		return errors;
	};

	const editBannerHandler = async () => {
		const errors = validateInputs();
		if (Object.keys(errors).length > 0) {
			setErrors(errors);
			Object.values(errors).forEach(error => toast.error(error));
			return;
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			await _banners.edit(id, name, image, isActive);
			ctxDispatch({ type: 'UPDATE_SUCCESS' });
			toast.success('Banner actualizado');
			navigate('/AdminScreen/AdminMarketing');
		} catch (ex) {
			dispatch({ type: 'UPDATE_FAIL' });
			toast.error(getError(ex));
		}
	};
	//#endregion
	useEffect(() => {
		fetchData();
	}, [id]);

	const uploadPicture = async (event) => {
		const file = event.target.files[0];
		const validExtensions = ['jpg', 'jpeg', 'png'];
		const fileExtension = file.name.split('.').pop().toLowerCase();
		if (!validExtensions.includes(fileExtension)) {
			toast.error('La imagen debe tener una extensión jpg, jpeg o png');
			return;
		}
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (image) {
			formData.append('documentId', image._id);
		}
		const response = await _banners.uploadBanner(formData);
		setImage(response.data);
		toast.success('imagen agregada correctamente');
	};

	return (
		<Modal size="lg" show={true} animation={false}>
			<div className="container  align-items-center">
				<Helmet>
					<title> Editar Banner</title>
				</Helmet>
				<div className="row justify-content-around">
					<div className="col-lg-11 col-10">
						<h1 className="my-3">
							<MdOutlineEdit></MdOutlineEdit>Editar Banner
						</h1>
					</div>
					<div className="col-lg-1 col-2">
						<MdClose
							className="my-3"
							style={{ fontSize: '30px', cursor: 'pointer' }}
							onClick={() => navigate(-1)}
						></MdClose>
					</div>
				</div>
				<Form>
					<div className="row">
						<Form.Group className="mb-3 col-lg-6" controlId="">
							<Form.Label>Titulo</Form.Label>
							<Form.Control
								onChange={(e) => setName(e.target.value)}
								defaultValue={name}
							></Form.Control>
						</Form.Group>
					</div>
					<div className="row">
						<Form.Group className="mb-3 col-lg-6" controlId="">
							<Form.Label>Subir banner</Form.Label>
							<Form.Control
								type="file"
								onChange={uploadPicture}
								name="bannerImg"
							></Form.Control>
						</Form.Group>
						<Form.Group className="mb-3 col-lg-22" controlId="">
							<Form.Label>Activo</Form.Label>
							<Form.Check
								checked={isActive}
								defaultValue={isActive}
								name="activeId"
								onChange={(e) => setIsActive(e.target.checked)}
							></Form.Check>
						</Form.Group>
					</div>
					<div className="row align-items-centers">
						<div className="col-lg-12 col-10 container-banner-preview">
							{image && image.fileLink ? (
								<img
									alt="banner_photo"
									src={image.fileLink}
									className="banner-preview"
								/>
							) : (
								<img
									alt="banner_photo"
									src="/images/emptyPhoto.png"
									className="banner-preview"
								/>
							)}
						</div>
					</div>
					<Row className="modal-btns">
						<Col>
							<Button className="cancelar" onClick={() => navigate(-1)}>
                Cancelar
							</Button>
						</Col>
						<Col>
							<PermissionWrapper fn={'banners'} scopes={[SCOPES.canEdit]}>
								<Button className="guardar" onClick={editBannerHandler}>
                Confirmar
								</Button>
							</PermissionWrapper>
						</Col>
					</Row>
				</Form>
			</div>
		</Modal>
	);
}

export default EditBanner;
