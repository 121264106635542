import React from 'react';
import moment from 'moment';

const DynamicTableField = ({ column, data }) => {
	function getFormattedValue(item, column) {
		const keys = column.split('.'); 
		let nestedValue = item;

		for (const key of keys) {
			if (nestedValue && nestedValue[key] !== undefined) {
				nestedValue = nestedValue[key];
			} else {
				// Special handling for countInStock
				if (column === 'countInStock') {
					return '0';
				}
				return 'N/A';
			}
		}

		// Special handling for countInStock when it's 0
		if (column === 'countInStock' && nestedValue === 0) {
			return '0';
		}

		const { type, typeAttributes } = column;

		if (type === 'currency') {
			const currencyCode = typeAttributes?.currencyCode || 'USD';
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: currencyCode,
			}).format(nestedValue);
		}

		if (type === 'date') {
			const dateFormat = typeAttributes?.dateFormat || 'DD-MM-YYYY';
			return moment(nestedValue).isValid()
				? moment(nestedValue).format(dateFormat)
				: 'N/A';
		}

		if (type === 'boolean') {
			return (
				<input
					type="checkbox"
					disabled
					checked={!!nestedValue}
				/>
			);
		}

		if (type === 'url') {
			const label = typeAttributes?.label || 'Click here';
			return (
				<a href={nestedValue} target="_blank" rel="noopener noreferrer">
					{label}
				</a>
			);
		}

		return nestedValue || 'N/A';
	}

	return <td>{getFormattedValue(data, column)}</td>;
};

export default DynamicTableField;
