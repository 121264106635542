import React, { useContext, useEffect, useLayoutEffect, useReducer } from 'react';
import { createGlobalStyle } from 'styled-components';
import './App.css';
import { setupAxiosInterceptors } from './restClient.js';
import { Routes, Route } from 'react-router-dom';
import { CustomBrowserRouter, customHistory } from './CustomBrowserRouter.jsx';
import 'react-toastify/dist/ReactToastify.css';
import MessageBox from './components/MessageBox/MessageBox.jsx';
import HomeScreen from './screens/Home/HomeScreen';
import ProductScreen from './screens/Product/ProductScreen.jsx';
import CheckoutLoginScreen from './screens/Cart/CheckoutLoginScreen.jsx';
import SignInScreen from './screens/SignIn/SignInScreen.jsx';
import ResetPassword from './screens/ResetPassword/ResetPassword.jsx';
import ShippingAddressScreen from './screens/ShippingAddress/ShippingAddressScreen.jsx';
import SignupScreen from './screens/SignUp/SignUpScreen';
import PaymentMethodScreen from './screens/PaymentMethod/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrder/PlaceOrderScreen';
import OrderScreen from './screens/Order/OrderScreen';
import Envios from './screens/ShippingInfo/ShippingInfo';
import Contacto from './screens/ContactInfo/ContactInfo';
import OrderHistoryScreen from './screens/OrderHistory/OrderHistoryScreen.jsx';
import ProfileScreen from './screens/Profile/ProfileScreen.jsx';
import SearchScreen from './screens/Search/SearchScreen.jsx';
import AdminScreen2 from './screens/AdminScreen2/AdminScreen2';
import AdminUsers from './screens/AdminUsers/AdminUsers';
import AdminProducts from './screens/AdminProducts/AdminProducts.jsx';
import NewProductScreen from './screens/NewProduct/NewProduct.jsx';
import AdminOrders from './screens/AdminOrders/AdminOrders.jsx';
import AdminFLAPs from './screens/PendingApprovals/PendingApprovals.jsx';
import AddBanner from './screens/AddBanner/AddBanner.jsx';
import EditBanner from './screens/EditBanner/EditBanner';
import AddSchedule from './screens/DeliverySchedule/AddSchedule.jsx';
import Navbarr from './components/Navbar/Navbar.jsx';
import Footerr from './components/Footer/Footer.jsx';
import CategoriesScreen from './screens/AdminCategories/CategoriesScreen';
import AddCategoryScreen from './screens/AddCategory/AddCategoryScreen.jsx';
import EditCategoryScreen from './screens/EditCategory/EditCategoryScreen.jsx';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.jsx';
import ChangePassword from './screens/ChangePassword/ChangePassword.jsx';
import VerifyUser from './screens/VerifyUser/VerifyUser.jsx';
import FaqPage from './screens/FaqPage/FaqPage';
import AdminNavbar from './components/AdminNavbar/AdminNavbar.jsx';
import AdminConfigScreen from './screens/AdminConfigScreen/AdminConfigScreen';
import AdminMarketing from './screens/AdminMarketingScreen/AdminMarketingScreen';
import LoadingBox from './components/LoadingBox/LoadingBox.jsx';
import MpSuccessScreen from './screens/MpSuccessScreen/MpSuccessScreen';
import MpFailureScreen from './screens/MpFailureScreen/MpFailureScreen';
import { Helmet } from 'react-helmet-async';
import CategoryDetails from './screens/CategoryDetails/CategoryDetails';
import AddressesScreen from './screens/AddressesScreen/AddressesScreen';
import VariantOptionsConfig from './screens/ProductVariantOptionsConfig/VariantOptionsConfigScreen';
import NewVariantOption from './screens/ProductVariantOptionsConfig/NewVariantOption/NewVariantOption';
import VerifyAdminEmail from './screens/VerifyAdminEmail/VerifyAdminEmail';
import PermissionWrapper from './permission-utils/PermissionWrapper.jsx';
import { SCOPES } from './permission-utils/scopes.js';
import _colors from './services/colors.service.js';
import _settings from './services/settings.service.js';
import { Store } from './Store';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'INIT_DATA_REQUEST':
		return { ...state, loading: true };
	case 'INIT_DATA_SUCCESS': {
		const { settings, colorsObj } = action.payload;
		return {
			...state,
			colors: colorsObj,
			gotaSettings: settings,
			loading: false,
		};
	}
	case 'INIT_DATA_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function App() {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { loadingOverlay } = state;
	const {userInfo} = state;
	const [{ loading, error, colors, gotaSettings }, dispatch] = useReducer(
		reducer,
		{
			colors: {},
			loading: true,
			error: '',
			gotaSettings: null,
		}
	);

	//getting theme colors-to do: load before page

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'INIT_DATA_REQUEST' });
				const { data: colorsObj } = await _colors.get();
				const { data: settings } = await _settings.getInitData();
				localStorage.setItem('globalMinStockProduct', JSON.stringify(settings.minStockToConfirm) || null);
				localStorage.setItem('usesShipping', JSON.stringify(settings.usesShipping || null));
				dispatch({ type: 'INIT_DATA_SUCCESS', payload: { colorsObj, settings } });
			} catch (error) {
				dispatch({ type: 'INIT_DATA_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, [userInfo]);

	useEffect(() => {
		setupAxiosInterceptors(ctxDispatch);
	}, [ctxDispatch]);

	//setting theme colors
	const GlobalStyles = createGlobalStyle`
:root {
  --primaryColor: ${colors.primaryColor};
  --secondaryColor: ${colors.secondaryColor};
  --background-color: ${colors.backgroundColor};
  --utilsColor:${colors.utilsColor};
  --navFontColor:${colors.navFontColor};
  --shadowNavColor:${colors.shadowNavColor};
  --IconsColor:${colors.iconsColor};
  --titlesColor:${colors.titlesColor};
  --btnFontColor:${colors.btnFontColor};
}
`;

	return (
		<>
			{' '}
			{loading ? (
				<LoadingBox></LoadingBox>
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<>
					{
						loadingOverlay && <LoadingOverlay/>
					}
					<GlobalStyles />	
				
					<CustomBrowserRouter history={customHistory}>

						{/* <BrowserRouter> */}
						<Helmet>
							{gotaSettings && (
								<>
									<title>{gotaSettings.companyName}</title>
									<meta
										name="description"
										content={`${
											gotaSettings.companysubName + ' |'
										} GotaMarket e-commerce by Gotasoft`}
									/>
								</>
							)}
							{gotaSettings && gotaSettings.favicon && (
								<>
									<link
										rel="icon"
										type="image/png"
										sizes="32x32"
										href={gotaSettings.favicon.fileLink}
									></link>
									<link
										rel="icon"
										type="image/png"
										sizes="16x16"
										href={gotaSettings.favicon.fileLink}
									></link>
									<link
										rel="apple-touch-icon"
										href={gotaSettings.favicon.fileLink}
									/>
								</>
							)}
						</Helmet>

						<ScrollToTop></ScrollToTop>
						<div className="d-flex flex-column site-container" content="notranslate">
							<Navbarr></Navbarr>
							<AdminNavbar/>
							<main>
								<Routes>
									<Route path="/" element={<HomeScreen />}></Route>
									<Route path="/checkout/login" element={<CheckoutLoginScreen />}/>
									<Route path="/checkout/shipping" element={<ShippingAddressScreen />}/>
									<Route path="/checkout/payment" element={<PaymentMethodScreen />}/>
									<Route
										path="/checkout/placeorder"
										element={<PlaceOrderScreen />}
									/>
									<Route
										path="/product/:slug"
										element={<ProductScreen />}
									></Route>
									<Route path="/signin" element={<SignInScreen />}></Route>
									<Route
										path="/reset-password/"
										element={<ResetPassword />}
									></Route>
									{/* <Route path="/reset-password/:email" element={<ResetPassword />}></Route> */}

									<Route path="/signup" element={<SignupScreen />}></Route>
									<Route path="/profile" element={<ProfileScreen />}></Route>
									<Route
										path="/addresses"
										element={<AddressesScreen />}
									></Route>
									<Route
										path="/change-password/:token"
										element={<ChangePassword />}
									></Route>
									<Route
										path="/verify-user/:token"
										element={<VerifyUser />}
									></Route>
									<Route
										path="/verify-admin-email/:token"
										element={<VerifyAdminEmail />}
									></Route>


									<Route path="/order/:id" element={<OrderScreen />}></Route>
									<Route
										path="/order/success/:id/:prefId"
										element={<MpSuccessScreen />}
									></Route>
									<Route
										path="/order/failure/:id/:prefId"
										element={<MpFailureScreen />}
									></Route>
									<Route
										path="/orderhistory"
										element={<OrderHistoryScreen />}
									></Route>
									<Route path="/search" element={<SearchScreen />}></Route>
									<Route path="/envios" element={<Envios />}></Route>
									<Route path="/contacto" element={<Contacto />}></Route>
									<Route path="/AdminScreen" element={
										<PermissionWrapper screen = {true} fn={'adminScreen'}  scopes={[SCOPES.canView]}>
											<AdminOrders />
										</PermissionWrapper>}>

									</Route>
									<Route
										path="/AdminScreen/productVariantOptions"
										element={<VariantOptionsConfig />}
									></Route>
									<Route
										path="/AdminScreen/productVariantOptions/new"
										element={<NewVariantOption />}
									></Route>
									<Route
										path="/AdminScreen/AdminScreen2"
										element={<AdminScreen2 />}
									></Route>
									<Route
										path="/AdminScreen/AdminUsers"
										element={
											<PermissionWrapper fn={'users'} screen = {true} scopes={[SCOPES.canView]}>
												<AdminUsers />
											</PermissionWrapper>}
									/>
									<Route
										path="/AdminScreen/AdminProducts/search"
										element={
											<PermissionWrapper screen  = {true} fn={'products'} scopes={[SCOPES.canView]}>
												<AdminProducts />
											</PermissionWrapper>
										}
									></Route>
									<Route
										path="/AdminScreen/AdminProducts/crud/:id?"
										element={
											<PermissionWrapper screen={true} fn={'products'}
												scopes={[SCOPES.canCreate]}>
												<NewProductScreen />
											</PermissionWrapper>} />
									<Route
										path="/AdminScreen/AdminOrders"
										element={
											<PermissionWrapper screen  = {true} fn={'orders'} scopes={[SCOPES.canView]}>
												<AdminOrders />
											</PermissionWrapper>
										}
									></Route>
									<Route
										path="/AdminScreen/AdminFlaps"
										element={
											// <PermissionWrapper screen  = {true} fn={"products"} scopes={[SCOPES.canView]}>
											<AdminFLAPs />
											// </PermissionWrapper>
										}
									></Route>
																		<Route
										path="/AdminScreen/AdminMarketing"
										element={
											<PermissionWrapper screen  = {true} fn={"banners"} scopes={[SCOPES.canView]}>
											<AdminMarketing />
											</PermissionWrapper>
										}
									></Route>
									<Route
										path="/AdminScreen/AdminBanners/AddBanner"
										element={
											<PermissionWrapper screen={true} fn={'banners'} scopes={[SCOPES.canCreate]}>
										<AddBanner />
											</PermissionWrapper>
										}
									></Route>
									<Route
										path="/AdminScreen/AdminBanners/EditBanner/:id"
										element={
											<PermissionWrapper screen={true} fn={'banners'} scopes={[SCOPES.canEdit]}>
										<EditBanner />
										</PermissionWrapper>}
									></Route>
									<Route
										path="/AdminScreen/AdminSchedules/AddSchedule"
										element={<AddSchedule />}
									></Route>
									<Route
										path="/AdminScreen/Categories"
										element={
											<PermissionWrapper screen = {true} fn = "categories" scopes={[SCOPES.canView]}>
												<CategoriesScreen />
											</PermissionWrapper>} />
									<Route
										path="/AdminScreen/Categories/AddCategory"
										element={
											<PermissionWrapper screen = {true} fn = "categories" scopes={[SCOPES.canCreate]}>
												<AddCategoryScreen />
											</PermissionWrapper>
										}
									/>
									<Route
										path="/AdminScreen/Categories/EditCategory/:id"
										element={
											<PermissionWrapper screen={true} fn="categories" scopes={[SCOPES.canEdit]}>
												<EditCategoryScreen />
											</PermissionWrapper>
										}
									/>
									<Route
										path="/AdminScreen/Categories/category/:id"
										element={
											<PermissionWrapper screen={true} fn="categories" scopes={[SCOPES.canView]}>
												<CategoryDetails />
											</PermissionWrapper>
										}
									/>
									<Route
										path="/AdminScreen/Config"
										element={
											<PermissionWrapper fn="config" screen={true} scopes={[SCOPES.canView]}>
												<AdminConfigScreen />
											</PermissionWrapper>
										}
									/>
									<Route path="/faq" element={<FaqPage />} />
								</Routes>
							</main>
						</div>
						<Footerr/>
					</CustomBrowserRouter>
					{/* </BrowserRouter> */}
				</>
			)}
		</>
	);
}

export default App;
