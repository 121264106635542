import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Dropdown,
	DropdownButton,
	Form,
	Row,
	Table,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import {
  FaEdit,
  FaInfoCircle,
  FaPlusCircle,
  FaRegTrashAlt,
} from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackBtn from "../../components/GoBackButton/GoBackBtn";
import MessageBox from "../../components/MessageBox/MessageBox";
import Pagination from "../../components/Pagination/Pagination";
import { Store } from "../../Store";
import { getError } from "../../utils";
import "./CategoryDetails.css";
import { SCOPES } from "../../permission-utils/scopes";
import PermissionWrapper from "../../permission-utils/PermissionWrapper";
import _category from "../../services/category.service";
import _product from "../../services/product.service";
import TabLoadingOverlay from "../../components/TabLoadingOverlay/TabLoadingOverlay";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

export default function CategoryDetails() {
  const { id } = useParams();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  // States
  const [category, setCategory] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [itemQuantity, setItemQuantity] = useState(0);
  const [siblingCount, setSiblingCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setSiblingCount(window.innerWidth < 400 ? 0 : 1);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch category data
  useEffect(() => {
    fetchData();
  },[]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await _category.getRelatedProducts(id);
      setCategory(result.data.category);
      setProducts(result.data.relatedProducts);
    } catch (error) {
      setError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [successDelete]);


  // Handle product deletion
  const submitDelete = async () => {
      setLoadingDelete(true);
      try {
        await _product.delete(productToDelete._id);
        toast.success("Producto eliminado correctamente");
        setSuccessDelete(true);
      } catch (err) {
        console.error(err);
        toast.error("Error. No se pudo borrar el producto.");
      } finally {
        setLoadingDelete(false);
      }
    
  };
  const handleDelete = async (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  }
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setProductToDelete(null);
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPosts = products.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Reset page when products are loaded
  useEffect(() => {
    if (products.length > 0) {
      setCurrentPage(1);
    }
  }, [products]);

  return (
    <>
   { loading &&   <TabLoadingOverlay className="admin"/>}
      <Helmet>
        <title>{category.name}</title>
      </Helmet>
      			<ConfirmationModal show={showDeleteModal} handleClose={handleCloseDeleteModal} handleConfirm={submitDelete} 
			    title="Eliminar producto" description="¿Estás seguro de querer eliminar este producto?" 
			  confirmCaption="Eliminar" cancelCaption="Cancelar" />
        <div className="col-sm-8 col-md-6 category-details-container admin-con container">
          <div className="p-4">
            <GoBackBtn>Volver a categorías</GoBackBtn>
            <div className="title d-flex align-items-center">
              <FaInfoCircle className="p-0"></FaInfoCircle>
            <h1 className="m-0">
              <span className="d-inline-block ps-1">{category.name}</span>{" "}
            </h1>
            </div>
          </div>
          <PermissionWrapper fn="categories" field="products" scopes={[SCOPES.canView]}>
            <div className="related-products-container p-5 pb-2">
              <Row className="justify-content-between">
                <h2 className="col-8">Productos de la categoría</h2>
                <PermissionWrapper fn="categories" field="products" scopes={[SCOPES.canCreate]}>
                  <LinkContainer
                    className="col-3"
                    to={{
                      pathname: `/AdminScreen/AdminProducts/crud`,
                      search: `category=${id}`,
                    }}
                  >
                    <button className="admin-button">
                      <FaPlusCircle className="pe-1"/>Agregar Producto
                    </button>
                  </LinkContainer>
                </PermissionWrapper>
              </Row>
              {products.length > 0 ? (
                <>
                  <Table hover size="sm">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Marca</th>
                        <th>Precio</th>
                        <th>Stock</th>
                        <th>Está activo?</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((product) => (
                        <tr key={product._id}>
                          <td>
                            <Link to={`/product/${product.slug}`}>
                              {product.name}
                            </Link>
                          </td>
                          <td>{product.brand}</td>
                          <td>${product.price}</td>
                          <td>{product.countInStock}</td>
                          <td>{product.active ? "SI" : "NO"}</td>
                          <td className="col-1 text-center">
                            <DropdownButton drop="start" title="">
                              <LinkContainer to={`/product/${product.slug}`}>
                                <Dropdown.Item>Ver Detalles</Dropdown.Item>
                              </LinkContainer>
                              <LinkContainer to={{
                                pathname: `/AdminScreen/AdminProducts/crud/${product._id}`}} >
                                <Dropdown.Item>
                                  <FaEdit> </FaEdit> Editar
                                </Dropdown.Item>
                              </LinkContainer>
                              <PermissionWrapper fn="categories" field="products" scopes={[SCOPES.canDelete]}>
                                <Dropdown.Item
                                  onClick={() => handleDelete(product)}
                                  disabled={loadingDelete}
                                >
                                  <FaRegTrashAlt> </FaRegTrashAlt>Eliminar
                                </Dropdown.Item>
                              </PermissionWrapper>
                            </DropdownButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="admin-pagination-container">
                    <Pagination
                      siblingCount={siblingCount}
                      className="pagination-bar"
                      totalCount={itemQuantity}
                      onPageChange={onPageChange}
                      currentPage={currentPage}
                      pageSize={itemsPerPage}
                    />
                  </div>
                </>
              ) : (
                <div className="mt-5">
                  <MessageBox>No hay productos asociados.</MessageBox>
                </div>
              )}
            </div>
          </PermissionWrapper>
        </div>
    </>
  );
}
