import axios from '../restClient';

const BASE_URI = '/faq';
class FaqService {
	async list() {
		const res = await axios.get(`${BASE_URI}`);
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async create(formData) {
		const res = await axios.post(BASE_URI, formData);
		return res;
	}
	async edit(id, formData) {
		const res = await axios.put(`${BASE_URI}/${id}`, formData);
		return res;
	}
}
export default new FaqService();
