/**
 * FaqQuestion Class
 * Represents a FAQ Question with its properties and validation rules
 */
class FaqQuestion {
	constructor(data = {}) {
		this.question = data.question || '';
		this.answer = data.answer || '';
		this.active = data.active ?? true;
	}
	/**
   * Creates a new FaqQuestion instance from form data
   * @param {Object} formData - The form data to create the question from
   * @returns {FaqQuestion} A new FaqQuestion instance
   */
	static create(formData) {
		return new FaqQuestion(formData);
	}

	/**
   * Validates the question data
   * @param {Object} data - The data to validate
   * @returns {Object} Object containing validation errors, if any
   */
	static validate(data) {
		const errors = {};

		// Question validation
		if (!data.question) {
			errors.question = 'La pregunta es requerida';
		} else if (data.question.length < 3) {
			errors.question = 'La pregunta debe tener al menos 3 caracteres';
		} else if (data.question.length > 200) {
			errors.question = 'La pregunta no puede exceder los 200 caracteres';
		}

		// Answer validation
		if (!data.answer) {
			errors.answer = 'La respuesta es requerida';
		} else if (data.answer.length < 10) {
			errors.answer = 'La respuesta debe tener al menos 10 caracteres';
		} else if (data.answer.length > 2000) {
			errors.answer = 'La respuesta no puede exceder los 2000 caracteres';
		}


		return errors;
	}

	/**
   * Converts the question to a plain object
   * @returns {Object} Plain object representation of the question
   */
	toJSON() {
		return {
			question: this.question,
			answer: this.answer,
			active: this.active
		};
	}

	/**
   * Checks if the question is valid
   * @returns {boolean} Whether the question is valid
   */
	isValid() {
		return Object.keys(FaqQuestion.validate(this.toJSON())).length === 0;
	}

	/**
   * Gets validation errors for the question
   * @returns {Object} Object containing validation errors
   */
	getErrors() {
		return FaqQuestion.validate(this.toJSON());
	}


}
export const faqQuestionFormTemplate = {
	question: {
		type: 'textarea',
		label: 'Pregunta',
		required: true,
		validator: (value) => {
			if (value.length < 3) return 'La pregunta debe tener al menos 3 caracteres';
			if (value.length > 200) return 'La pregunta no puede exceder los 200 caracteres';
			return null;
		}
	},
	answer: {
		type: 'textarea',
		label: 'Respuesta',
		required: true,
		validator: (value) => {
			if (value.length < 3) return 'La respuesta debe tener al menos 3 caracteres';
			if (value.length > 2000) return 'La respuesta no puede exceder los 2000 caracteres';
			return null;
		}
	},
	active: {
		type: 'boolean',
		label: 'Activo',
		defaultValue: true
	}
};

export default FaqQuestion;
