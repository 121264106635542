import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TbExchange } from 'react-icons/tb';
import {  BsCheck, BsX} from 'react-icons/bs';
import { Card, Modal, Button } from 'react-bootstrap';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { useContext } from 'react';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { hasPermission, toCamelCase } from '../../utils';
import { Helmet } from 'react-helmet-async';
import './PendingApprovals.css';
import { SCOPES } from '../../permission-utils/scopes.js';
import DynamicListTable from '../../components/DynamicTable/DynamicListTable.js';
import FlapService from '../../services/flap.service.js'; 
import Tab from '../../components/Tabs/Tabs.jsx';
import useQueryFilters from '../../hooks/useQueryFilters.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS': {
		const { flaps, pendingCount, rejectedCount, approvedCount, allCount } = action.payload;
		return {
			...state,
			loading: false,
			flaps,
			pendingCount,
			rejectedCount,
			approvedCount,
			allCount
		};
	}
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'OPEN_DETAILS_MODAL':
		return { ...state, selectedId: action.payload, modalIsOpen: true };
	case 'CLOSE_MODAL':
		return { ...state, selectedId: null, modalIsOpen: false };
	default:
		return state;
	}
};

function AdminFLAPs() {
	const [reducerState,
		dispatch,
	] = useReducer(reducer, {
		flaps: [],
		loading: true,
		error: '',
		modalIsOpen: false,
		selectedId: null,
		itemQuantity: 0,
		loadingDelete: false,
		successDelete: false,
		pendingCount: 0,
		rejectedCount: 0,
		approvedCount: 0,
		allCount: 0
	});
	const
		{
			loading,
			error,
			flaps,
			successDelete,
			allCount
		} = reducerState;
	const { state } = useContext(Store);
	const { userInfo } = state;
	const { search } = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(search);
	const [showModal, setShowModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [comment, setComment] = useState(''); 

	const statuses = [
		{ label: 'Todos', value: 'All' },
		{ label: 'Pendientes', value: 'Pending' },
		{ label: 'Aprobados', value: 'Approved' },
		{ label: 'Rechazados', value: 'Rejected' },
	];

	const { setPage, setFieldsAndSubmit, fields, setFields } =
    useQueryFilters({ baseUrl: '/AdminScreen/Adminflaps', resizeWidth: 400 });

	const getTotalCurrentCount = () => fields.status ? reducerState[toCamelCase(fields.status) + 'Count'] : allCount;

	useEffect(() => {
		const newSearchParams = new URLSearchParams();
		Object.entries(fields).forEach(([key, value]) => {
			if (value !== undefined && value !== null && value !== '') {
				newSearchParams.append(key, value);
			}
		});
		const newSearch = newSearchParams.toString();
		const currentSearch = search.replace('?', '');
		if (newSearch !== currentSearch) {
			navigate(`?${newSearch}`, { replace: true });
		}
	}, [fields]);

	useEffect(() => {
		const currTotalCount = getTotalCurrentCount();
		if (parseInt(fields.page) > Math.ceil(currTotalCount / fields.pageSize)) {
			setPage(1); // Reset page if it's out of bounds
		}
	}, [fields]);

	// Fetch data when search params or delete status changes
	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const { data } = await FlapService.list(searchParams);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				console.error(error);
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};

		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [search, successDelete]);

	const seeDetailsHandler = (flap) =>
		dispatch({ type: 'OPEN_DETAILS_MODAL', payload: flap._id });

	const closeModal = () => {
		setShowModal(false);
		setComment('');
	};
	
	const openModal = (action, item) => {
		setSelectedItem(item);
		setShowModal(true);
	};

	const handleConfirm = async (action) => {
		closeModal();
		if (action === 'accept') {
			await acceptChangeConfirm(selectedItem, comment); 
		} else if (action === 'reject') {
			await rejectChangeConfirm(selectedItem, comment);
		}
	};

	async function deleteHandler(flap) {
		if (window.confirm('Are you sure you want to delete this FLAP?')) {
			try {
				await FlapService.delete(flap._id);
				toast.success('FLAP deleted successfully');
				dispatch({ type: 'DELETE_SUCCESS' });
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(error);
				toast.error('Error. Could not delete the FLAP');
			}
		}
	}

	const acceptChangeConfirm = async (flap, comment) => {
		try {
			await FlapService.acceptChange(flap._id, comment); 
			toast.success('Change accepted successfully');
			dispatch({ type: 'DELETE_SUCCESS' });
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			console.error(error);
			toast.error('Error. Could not accept the change');
		}
	};
  
	const rejectChangeConfirm = async (flap, comment) => {
		try {
			await FlapService.rejectChange(flap._id, comment);
			toast.success('Change rejected successfully');
			dispatch({ type: 'DELETE_SUCCESS' });
		} catch (error) {
			dispatch({ type: 'DELETE_FAIL' });
			console.error(error);
			toast.error('Error. Could not reject the change');
		}
	};

	const [actions] = useState({
		deleteHandler: {
			handler: hasPermission(userInfo.role, 'products', [SCOPES.canDelete]) ? deleteHandler : null,
			label: 'Eliminar'
		},
		previewHandler: {
			handler: seeDetailsHandler,
			label: 'Vista Previa'
		},
		acceptChangeHandler: {
			handler: (item) => openModal('accept', item),
			label: 'Aceptar Cambios',
			restrictions: {
				status: 'Pending'
			}
		},
		rejectChangeHandler: {
			handler: (item) => openModal('reject', item),
			label: 'Rechazar Cambios',
			restrictions: {
				status: 'Pending'
			}
		},
		verifyHandler: {
			handler: (item) => openModal('verify', item),
			label: 'Verificar'
		}
	});

	return (
		<>
			<Modal
				show={showModal}
				centered
				onHide={closeModal}
				dialogClassName="verify-flap-modal"
			>
				<Modal.Header>
					<h5 className="modal-title"><strong>Aceptar o rechazar cambios</strong></h5>

					<Button variant="link" onClick={closeModal} className="close-btn">
						<BsX className="close-icon" />
					</Button>
				</Modal.Header>
				<Modal.Body>
					{selectedItem && (
						<table className="table mt-3">
							<thead>
								<tr>
									<th>Registro</th>
									<th>Campo</th>
									<th>Valor Actual</th>
									<th>Nuevo Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{selectedItem.record.name}</td>
									<td>{selectedItem.field}</td>
									<td>{selectedItem.currentValue}</td>
									<td>{selectedItem.newValue}</td>
								</tr>
							</tbody>
						</table>
					)}
					<div>
						<label htmlFor="comments"><strong>Comentarios</strong> (opcional):</label>
						<textarea id="comments" className="comments-form-control" placeholder="Deja tus comentarios..."    value={comment} 
							onChange={(e) => setComment(e.target.value)} />
					</div>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<Button className="reject-btn" onClick={() => { handleConfirm('reject'); }}>
						<BsX className="icon-confirm" /> Rechazar
					</Button>
					<Button className="accept-btn" onClick={() => { handleConfirm('accept'); }}>
						<BsCheck className="icon-confirm" /> Aceptar
					</Button>
				</Modal.Footer>
			</Modal>

			<Helmet>
				<title>Control de cambios</title>
			</Helmet>
			<div id="admin-flaps-container" className="admin-con p-3 pt-2">
				<h1 className="text-right section-title">
					<TbExchange /> {' '}Control de cambios
				</h1>
				<div className="tabs">
					<div className="tabs-container" style={{ width: '80%' }}>
						{statuses.map(status => (
							<Tab
								key={status.value}
								status={status.label}
								showQuantityInBadge={status.value === 'Pending'}
								active={fields.status === status.value || (!fields.status && status.value.toLowerCase() === 'all')}
								onClick={() => setFieldsAndSubmit({status: status.value})}
								count={reducerState[toCamelCase(status.value) + 'Count']}
							/>
						))}
					</div>
	
				</div>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<Card>
						{/* <FormFilter /> */}
						{flaps.length > 0 ? (
							<DynamicListTable
								dataName="flaps"
								data={flaps}
								loading={loading}
								error={error}
								columnInfo={[
									{ 'record.name': { type: 'string', label: 'Registro' } },
									{ model: { type: 'string', label: 'Tipo' } },
									{ field: { type: 'string', label: 'Campo' } },
									{ currentValue: { type: 'string', label: 'Valor Actual' } },
									{ newValue: { type: 'string', label: 'Nuevo Valor' } },
									{ 'requestedBy.name': { type: 'string', label: 'Solicitud' } }
								]}
								count={getTotalCurrentCount()}
								page={fields.page}
								pageSize={fields.pageSize}
								onPageChange={(newPage) => {
									setFields(prev => ({...prev, page: newPage}));
								}}
								actionButtons={actions}
								showCheckboxColumn={false}
								frontPaginator={false}
							/>
						) : (
							<MessageBox className={'my-2'}>
                No pending approvals found
							</MessageBox>
						)}
					</Card>
				)}
			</div>
		</>
	);
}

export default AdminFLAPs;
