import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {  Col, Button } from 'react-bootstrap';
import BtnOutlineSecondary from '../BtnOutlineSecondary/BtnOutlineSecondary';
import './NewImageCropper.css';
import TabLoadingOverlay from '../TabLoadingOverlay/TabLoadingOverlay';
import { imgPreview } from './cropperUtils';
import {toast} from 'react-toastify';

export default function NewImageCropper({ imgSrc, setImgSrc, onCrop }) {
    const [crop, setCrop] = useState();
    const imgRef = useRef(null)
    const [completedCrop, setCompletedCrop] = useState();
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState(false);

    async function onCompleteCrop() {
        if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current
        ) {
            const preview = await imgPreview(
                imgRef.current,
                completedCrop
            )
            setPreviewImage(preview)
        }
    }
    const handleCancel = () => {
        if (previewImage) {
            setPreviewImage(null);
        } else {
            setImgSrc(null);
        }
    }
    const handleConfirm = async () => {
        if (previewImage) {
            try {
                setLoading(true)
                await onCrop(previewImage);
            } catch (ex) {
                console.error(ex);
                toast.error('Error. No se pudo procesar la imagen.');
            } finally {
                setLoading(false);
            }
        } else if (crop) {
            onCompleteCrop();
        } else {
            toast.warning('Por favor, seleccione un área para recortar');
        }
    }
    function onImageLoad(e) {
        setCrop({ unit: 'px', x: 0, y: 0, width: 400, height: 400 })
    }

    return (
        <div className='new-image-cropper pb-0'>
                    <div className="cropper-container">
                        {previewImage ? <img alt="Crop preview" className='mx-auto img-fluid h-100' src={previewImage.src} /> :
                            <ReactCrop
                                className='mx-auto'
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                maxWidth={400}
                                maxHeight={400}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        }
                    </div>


                    <div className='d-flex justify-content-around mt-2'>
                        <Col xs={5}>
                            <Button variant="outline-danger" onClick={handleCancel}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col xs={5}>
                            <BtnOutlineSecondary clickHandler={handleConfirm}>
                                Confirmar
                            </BtnOutlineSecondary>
                        </Col>
                    </div>
                {
                        loading && <TabLoadingOverlay />
                } 
        </div>
    )
}
