import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VariantGenerationWizardStepper from './VariantGenerationWizardStepper';
import Form from 'react-bootstrap/Form';
import './VariantGenerationWizard.css';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import PillInput from '../../components/PillInput/PillInput';
import Accordion from 'react-bootstrap/Accordion';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import _productVariant from '../../services/productVariant.service';
import { getError } from '../../utils';
import { useNavigate } from 'react-router-dom';

export default function VariantGenerationWizard({ show,  close: onClose, selectedProducts }) {
	const [activeStep, setActiveStep] = useState(1);
	const [newOption , setNewOption] = useState('');
	const [baseProductNewName , setBaseProductNewName] = useState('');
	const [productList, setProductList] = useState([]);
	const [baseProduct, setBaseProduct] = useState({});
	const navigate = useNavigate();
	const nextStep = () => { setActiveStep(step => step + 1); };
	const previousStep = () => { setActiveStep(step => step - 1); };
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (selectedProducts?.length) {
			setProductList(selectedProducts.map(p => ({
				product: null,
				active: p.active,
				countInStock: p.countInStock,
				price: p.price,
				image: p.image,
				options: []
			})));
		}
		if(!show) {
			emptyData();
		}
	}, [selectedProducts, show]);

	const nextStepHandler = () => {
		if (activeStep === 1 && !baseProduct?._id) {
			toast.error('Debes seleccionar un producto base');
		} else if (activeStep === 2 && !options.length) {
			toast.error('Debes agregar al menos una opción');
		} else {
			nextStep();
		}
	};
	const submitVariants = async () => {
		try {
			if (validateVariants()) {
				const idsToConvertToDraft = selectedProducts.filter(p => p._id != baseProduct._id).map(p => p._id);
				await _productVariant.generateVariantsFromProduct(productList, idsToConvertToDraft, baseProductNewName);
				close();
				toast.success('Variantes generadas con éxito');
				const slug = selectedProducts.find((p) => p._id === baseProduct._id).slug;
				navigate(`/product/${encodeURIComponent(slug)}`);
			} else {
				toast.error('Debes completar todos los campos');
			}
		} catch (ex) {
			console.error(ex);
			toast.error(getError(ex));
		}
	};
	const validateVariants = () => {
		return productList.every(p =>
			p.countInStock >= 0 && p.price >= 0 &&
      options.every(opt => {
        const optIndex= p.options.findIndex(o => o.option.name === opt.name);
        return p.options[optIndex]?.value})
    )
}
  const addOption = () => {
    const newOptionLower = newOption.toLowerCase();
    if (newOptionLower && !options.some(opt => opt.name.toLowerCase() === newOptionLower)) {
      const newOptionCapitalized = newOptionLower[0].toUpperCase() + newOptionLower.slice(1).toLowerCase();
      setOptions([...options, { name: newOptionCapitalized }]);
      setProductList(prodList => prodList
        .map(p => ({ ...p, 
          options: [...p.options, 
            { option: {name: newOptionCapitalized}, value: null  }] })));
    } else if(!newOptionLower) {
      toast.error("Debes agregar una opción");
    }else{
      toast.error("Esta opción ya existe");
    }
  }

	const removeOption = (option) => {
		setOptions(options.filter(opt => opt.name !== option));
		setProductList(prodList => prodList
			.map(p => {
				const newP = { ...p };
				const optIndex = newP.options.findIndex(opt => opt.option.name === option);
				newP.options.splice(optIndex, 1);
				return newP;
			}));
	};
	const emptyData= () => {
		setActiveStep(1);
		setOptions([]);
		setNewOption('');
		setBaseProduct(null);
	};
	const close = () => {
		onClose();
		emptyData();
	};
	const updateProduct = (index, key, value) => {
		setProductList(list => {
			const newList = [...list];
			newList[index][key] = value;
			return newList;
		});
	};
	const handleSelectBaseProduct =  (id) => {
		const baseProd = selectedProducts.find(p => p._id === id);
		setBaseProduct(baseProd);
		setProductList(list => list.map(p => ({ ...p, product: id })));
		setBaseProductNewName(baseProd.name);
	};
	const updateOption = (index, option, value) => {
		setProductList(list => {
			const newList = [...list];
			const optIndex = newList[index].options.findIndex(opt => opt.option.name === option);
			newList[index]['options'][optIndex]['value'] = {value};
			return newList;
		});
	};
	const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  
	return <Modal show={show} animation = {false} onHide={close} dialogClassName={'variant-generation-wizard'}>
		<Modal.Header closeButton>
			<Modal.Title>Generador de variantes</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<VariantGenerationWizardStepper activeStep={activeStep} />
			{
				activeStep === 1 &&
        <div className="step-content">
        	<p className="step-description">
            Selecciona el producto base para el cual deseas generar variantes
        	</p>
        	<Form.Floating className="mb-3">
        		<Form.Control
        			as="select"
        			aria-label="Selecciona un producto"
        			onChange={ e => handleSelectBaseProduct(e.target.value)}
        			value={baseProduct?._id}
        		>
        			<option>-</option>
        			{
        				selectedProducts.map(p => <option key={p._id} value={p._id}>{p.name}</option>)
        			}
        		</Form.Control>
        		<label htmlFor="floatingSelectCustom">Selecciona un producto</label>
        	</Form.Floating>
        	{
        		baseProduct && <>
        			<p className="step-description">
                  Modifica el nombre del producto base
        			</p>
        			<FloatingLabel label='Nombre' className='mb-2'>
        				<Form.Control value = {baseProductNewName} placeholder="Precio" required
        					onChange={e => setBaseProductNewName(e.target.value)} />
        			</FloatingLabel>
        		</>
        	}
        </div>
			}
			{
				activeStep === 2 && <div className="step-content">
					<p className="step-description">
            Agrega las opciones que deseas que tenga tu producto
          </p>
          <Form.Group className="mb-3" controlId="formOptions">
            <Form.Label>Opciones</Form.Label>
            <InputGroup>
              <Form.Control type="text" value = {newOption} onChange = {e => setNewOption(e.target.value)}placeholder="Agregar opción" />
              <Button variant="outline-secondary"  className = "p-0" onClick={addOption}>
                <FaPlus/>
              </Button>
            </InputGroup>
          </Form.Group>
          <div className="row px-2">
            {
              options.map((option, index) =>
                <PillInput value={option.name} id={option.name}
                  removeHandler={removeOption}
                  key={option.name} />
              )
            }
          </div>
        </div>
      }
      {
        activeStep === 3 && <div className="step-content">
          <Accordion>
            {
              selectedProducts
                .map((p, i) => {
                  return <Accordion.Item eventKey={i} key={p.name}> {''.replace}
                    <Accordion.Header>Variante {i + 1} | Nombre original: {p.name} </Accordion.Header>
                    <Accordion.Body className='p-2 row'>
                      <Col md={6}>
                        < figure >
                          <img className='w-100 h-100' src={p.image.fileLink} />
                        </figure>
                      </Col>
                      <Col md={6}>
                        <h2 className='mb-1'>
                          Datos básicos
												</h2>
												<FloatingLabel label='Stock' className='mb-2'>
													<Form.Control placeholder="Stock" value={productList[i].countInStock}
														onChange={e => updateProduct(i, 'countInStock', e.target.value)}
														required />
												</FloatingLabel>
												<FloatingLabel label='Precio' className='mb-2'>
													<Form.Control placeholder="Precio" required value={productList[i].price}
														onChange={e => updateProduct(i, 'price', e.target.value)} />
												</FloatingLabel>
												<Form.Check 
													className='mb-2'
													checked={productList[i].active}
													onChange={e => updateProduct(i, 'active', e.target.checked)} 
													type="switch"
													id="custom-switch"
													label="Activo"
												/>
												<h2 className='mb-1'>
                          Opciones de variante
												</h2>
												{options.map((option, j) =>
													<FloatingLabel key={j} label={capitalizeFirstChar(option.name)}
														className='mb-1'>
														<Form.Control placeholder={option.name} required  
															onChange={e => updateOption(i, option.name, e.target.value)}/>
													</FloatingLabel>

												)}
											</Col>
										</Accordion.Body>
									</Accordion.Item>;
								})
						}

					</Accordion>
				</div>
			}
		</Modal.Body>
		<Modal.Footer className='d-flex justify-content-around w-100'>
			<Button variant="secondary" className='m-0' onClick={activeStep != 1 ? previousStep: close }>
				{activeStep != 1 ? 'Atrás' : 'Cerrar'}
			</Button>
			<Button variant="primary" className='m-0'
				onClick={activeStep != 3 ? nextStepHandler : submitVariants}>
				{activeStep != 3 ? 'Continuar' : 'Finalizar'}
			</Button>
		</Modal.Footer>
	</Modal>;
}
