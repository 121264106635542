import React, { useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { useEffect } from 'react';
import './FaqQuestionsConfig.css';
import TextFloatingLabel from '../../TextFloatingLabel/TextFloatingLabel.jsx';
import { toast } from 'react-toastify';
import _faq from '../../../services/faq.service.js';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal.jsx';
import DynamicFormModal from '../../DynamicFormModal/DynamicFormModal.jsx';
import { faqQuestionFormTemplate } from '../../../models/FaqQuestion.js';

export default function FaqQuestionsConfig() {
	const [questions, setQuestions] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState('');
	const [loadQuestions, setLoadQuestions] = useState(true);
	const [deleteShow, setDeleteShow] = useState(false);
	const [show, setShow] = useState(false);
	
	const fetchQuestions = async () => {
		if (loadQuestions) {
			try {
				const fetchedQuestions = await _faq.list();
				setQuestions(fetchedQuestions.data);
			} catch (ex) {
				console.error(ex);
				toast.error('Error. No se pudo obtener las preguntas de ayuda.');
			}
			setLoadQuestions(false);
		}
	};
	const deleteQuestion = async (id) => {
		try {
			await _faq.delete(id);
			toast.success('Pregunta eliminada');
			handleClose();
			setSelectedQuestion(null);
		} catch (ex) {
			toast.error('Error. No se pudo eliminar la pregunta.');
			console.error(ex);
		}
	};
	useEffect(() => {
		fetchQuestions();
	}, [loadQuestions]);


	const handleOpen = (paramQuestion = null) => {
		setShow(true);
		setSelectedQuestion(paramQuestion);
	};

	const handleClose = () => {
		setShow(false);
		setDeleteShow(false);
		setLoadQuestions(true);
		setSelectedQuestion('');
	};
	const handleSelectDeletion = (q) => {
		setSelectedQuestion(q);
		setDeleteShow(true);
	};
	const handleSubmit = async (formData) => {
		try {
			if (selectedQuestion) {
				await _faq.edit(selectedQuestion._id, formData);
			} else {
				await _faq.create(formData);
			}
			toast.success('Pregunta guardada');
			handleClose();
		} catch (ex) {
			console.error(ex);
			toast.error('Error. No se pudo guardar la pregunta.');
		}
	};
	const DeleteQuestionModal = () => (
		<ConfirmationModal
			show={deleteShow}
			handleClose={handleClose}
			handleConfirm={() => deleteQuestion(selectedQuestion._id)}
			title="Eliminar pregunta"
			description="¿Estás seguro de querer eliminar esta pregunta?"
			confirmCaption="Eliminar"
			cancelCaption="Cancelar"
		/>
	);
	const QuestionEditor = () => {
		// Convert selectedQuestion to the new structure required by DynamicForm
		const formattedInitialState = useMemo(() => {
			return selectedQuestion ? {
				_id: {
					value: selectedQuestion._id,
					disabled: true
				},
				question: {
					value: selectedQuestion.question,
					disabled: false
				},
				answer: {
					value: selectedQuestion.answer,
					disabled: false
				},
				active: {
					value: selectedQuestion.active,
					disabled: false
				}
			} : null;
		}, [selectedQuestion]);

		return (
			<DynamicFormModal
				show={show}
				handleClose={handleClose}
				title={selectedQuestion ? 'Editar pregunta' : 'Agregar pregunta'}
				onSubmit={handleSubmit}
				formTemplate={faqQuestionFormTemplate}
				initialState={formattedInitialState}
				submitButtonText="Guardar"
				cancelButtonText="Cancelar"
			/>
		);
	};

	return <div id="faq-config">
		<DeleteQuestionModal/>
		<QuestionEditor/>
		<Row className="align-items-start">
			<Col sm={6} xs={12}>
				<button className="admin-button mb-2" onClick={() => handleOpen()}>
					Nueva pregunta <FaPlus/>
				</button>
				<Row>
					<Col xs={10}>
						<ListGroup id="lista-preguntas">
							{questions.length > 0 &&
                  questions.map((q, index) => {
                  	return (
                  		<ListGroup.Item key={index} id={q._id}>
                  			<Row className="justify-content-between px-2">
                  				<Col xs={8}>{q.question}</Col>
                  				<Col
                  					sm={2}
                  					xs = {3}
                  					className="d-flex justify-content-around p-0"
                  				>
                  					<button
                  						onClick={() => setSelectedQuestion(q)}
                  						className="action-button"
                  					>
                  						<FaEye></FaEye>
                  					</button>
                  					<button
                  						className="action-button"
                  						onClick={() => handleSelectDeletion(q)}
                  					>
                  						<FaTrash></FaTrash>
                  					</button>
                  				</Col>
                  			</Row>
                  		</ListGroup.Item>
                  	);
                  })}
						</ListGroup>
					</Col>
				</Row>
			</Col>
			{selectedQuestion && (
				<Col sm={5} xs={12}>
					<Row
						id="faq-data"
						className=" justify-content-start align-items-center mb-1"
					>
						<TextFloatingLabel
							className="mb-2"
							label="Pregunta"
							edit={() => handleOpen(selectedQuestion)}
						>
							{selectedQuestion?.question}
						</TextFloatingLabel>
						<TextFloatingLabel
							edit={() => handleOpen(selectedQuestion)}
							label="Respuesta"
						>
							{selectedQuestion?.answer}
						</TextFloatingLabel>
					</Row>
					<Row className="align-items-center">
						<span id="label-activo" className="inline-block col-1 me-1">
                Activo
						</span>
						<Form.Check
							readOnly
							className="col-1 pe-0 me-1"
							checked={selectedQuestion?.active}
						></Form.Check>
						<button
							id="pen-checkbox-faq"
							className="col-1 pen-edit-method"
							onClick={() => handleOpen(selectedQuestion)}
						>
							<FaPen></FaPen>
						</button>
					</Row>
				</Col>
			)}
		</Row>
	</div>;
}