import axios from '../restClient';

const BASE_URI = '/discountCodes';

class DiscountCodesService {
	async list() {
		try{
			const res = await axios.get(`${BASE_URI}`);
			return res;

		} catch {
			throw new Error('Error. No se pudo obtener los codigos de descuento');
		}
	}
	//TODO crear address como un clase con metodos de validacion.
	async create(data) {
		const res = await axios.post(BASE_URI, data);
		return res;
	}

	//get by code
	async getByCode(code) {
		if (code) {
			const res = await axios.get(`${BASE_URI}/get-by-code/${code}`);
			return res;
		} else {
			return null;
		}
	}

	async delete(codeId) {
		if (codeId) {
			const res = await axios.delete(`${BASE_URI}/${codeId}`);
			return res;
		} else {
			console.error('Missing code Id');
			throw new Error('Error. No se pudo eliminar el codigo de descuento');
		}
	}

	async inactiveCode(codeId) {
		if (codeId) {
			try{
				const res = await axios.put(`${BASE_URI}/deactivate/${codeId}`);
				return res;
			} catch{
				throw new Error('Error. No se pudo desactivar el codigo');
			}
		} else {
			console.error('Missing codeId');
			throw new Error('Error. No se pudo desactivar el codigo');
		}
	}

	async updateDates(codeId, dates) {
		if (codeId) {
			const res = await axios.put(`${BASE_URI}/update-dates/${codeId}`, dates);
			return res;
		} else {
			console.error('Missing codeId');
			throw new Error('Error. No se pudo actualizar las fechas del código de descuento.');
		}
	}

	async useCoupon(code) {
		try {
			const res = await axios.get(`${BASE_URI}/use-coupon/${code}`);
			const discountCode = res.data;
			if (discountCode) {
				return discountCode;
			} else {
				return null;
			}
		} catch {
			throw new Error('Error. No se pudo obtener el porcentaje de descuento.');
		}
	}

	async update(codeId, data) {
		if (codeId && data) {
			const res = await axios.put(`${BASE_URI}/${codeId}`, data);
			return res;
		} else {
			console.error('Missing codeId or data');
			throw new Error('Error. No se pudo actualizar el código de descuento.');
		}
	}
}

export default new DiscountCodesService();
