import React, { useEffect, useMemo, useState } from 'react';
import './PaymentMethodConfig.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { FaEye, FaTrash, FaPen } from 'react-icons/fa';
import BtnPlus from '../PlusButton/BtnPlus';
import { toast } from 'react-toastify';
import _utils from '../../services/utils.service';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DynamicFormModal from '../DynamicFormModal/DynamicFormModal';
import PaymentMethod from '../../models/PaymentMethod';
import _mercadopago from '../../services/mercadopago.service';

export default function PaymentMethodConfig() {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedMethod, setSelectedMethod] = useState(null);
	const [activeMethod, setActiveMethod] = useState(null); // Track the currently active method
	const [loadMethods, setLoadMethods] = useState(true);
	const [show, setShow] = useState(false);
	const [deleteShow, setDeleteShow] = useState(false);
	const [lockMercadopago, setLockMercadopago] = useState(false);

	const fetchMethods = async () => {
		if (loadMethods) {
			try {
				const methods = await _utils.listPaymentMethods();
				setPaymentMethods(methods.data);
			} catch (ex) {
				console.error(ex);
				toast.error('Error. No se pudo cargar los métodos de pago.');
			}
			setLoadMethods(false);
		}
	};

	useEffect(() => {
		initData();
	}, [loadMethods]);

	const initData = async() => {
		await fetchMethods();
		await checkMercadopago();
	};

	
	const handleOpen = (paramMethod = null) => {
		setShow(true);
		setSelectedMethod(paramMethod);
	};
	const handleClose = () => {
		setShow(false);
		setDeleteShow(false);
		setLoadMethods(true);
		setSelectedMethod(null);
		setActiveMethod(null);
	};
	
	const handleEdit = () => {
		setShow(true);
	};
	const handleSelectDeletion = (pm) => {
		setSelectedMethod(pm);
		setDeleteShow(true);
	};
	/**
	 * Selects a method and toggles the active method
	 * @param {Object} pm - The method to select
	 */
	const handleSelectMethod = (pm = null) => {
		setSelectedMethod(pm);
		setActiveMethod(activeMethod === pm ? null : pm); // Toggle the active method
	};

	const submitMethod = async (formData) => {
		
		try {
			if (selectedMethod) {
				await _utils.editMethod(selectedMethod._id, formData);
			} else {
				await _utils.createMethod(formData);
			}
			toast.success('Metodo guardado');
			handleClose();
		} catch (ex) {
			toast.error('Error. No se pudo guardar el método de pago.');
			console.error(ex);
		}
	};

	const checkMercadopago = async () => {
		try {
			const {data} = await _mercadopago.getAccessToken();
			setLockMercadopago(!data);
		} catch (ex) {
			console.error('Error checking MercadoPago usage:', ex);
			toast.error('Error al verificar el uso de MercadoPago.');
		}
	};
	const DeletePaymentMethodModal = () => (
		<ConfirmationModal
			show={deleteShow}
			handleClose={handleClose}
			handleConfirm={() => deleteMethod(selectedMethod._id)}
			title="Eliminar método de pago"
			description="¿Estás seguro de querer eliminar este método de pago?"
			confirmCaption="Eliminar"
			cancelCaption="Cancelar"
		/>
	);
	const QuestionEditor = () => {
		const methodIsMercadoPago = selectedMethod?.method?.toLowerCase() === 'mercadopago';
		console.log(lockMercadopago);
		const formattedInitialState = useMemo(() => {
			return selectedMethod ? {
				_id: {
					value: selectedMethod._id,
					disabled: true
				},
				method: {
					value: selectedMethod.method,
					disabled: methodIsMercadoPago
				},
				details: {
					value: selectedMethod.details,
					disabled: methodIsMercadoPago
				},
				active: {
					value: selectedMethod.active,
					disabled: methodIsMercadoPago && lockMercadopago
				},
				uploadAttachment: {
					value: selectedMethod.uploadAttachment,
					disabled: methodIsMercadoPago 
				},
				chargePercent: {
					value: selectedMethod.chargePercent,
					disabled: methodIsMercadoPago && lockMercadopago
				},
				usesPaymentGateway: {
					value: selectedMethod.usesPaymentGateway,
					disabled: methodIsMercadoPago
				},
			} : null;
		}, [selectedMethod]);
		return (
			<DynamicFormModal
				show={show}
				handleClose={handleClose}
				title={selectedMethod ? 'Editar método de pago' : 'Agregar método de pago'}
				onSubmit={submitMethod}
				formTemplate={PaymentMethod.formTemplate}
				initialState={formattedInitialState}
				submitButtonText="Guardar"
				cancelButtonText="Cancelar"
			/>
		);
	};
	const deleteMethod = async (id) => {
		try {
			await _utils.deleteMethod(id);
			toast.success('Metodo eliminado');
		} catch (ex) {
			toast.error('Error. No se pudo eliminar el método de pago.');
			console.error(ex);
		}
	};

	return (
		<>
			<div id="payment-config">
				<QuestionEditor/>
				<DeletePaymentMethodModal/>
				<Row>
					<Col xs={12} md={5}>
						<div className="d-block ms-1">
							<p id="label-boton" className="d-inline-block">
                Agregar nuevo metodo.
							</p>
							<BtnPlus
								className="col-1 d-inline-block mb-1 ms-2"
								id="new-method-btn"
								onClick={() => handleOpen()}
							></BtnPlus>
						</div>
						<Row>
							<Col xs={11}>
								<ListGroup id="lista-metodos">
									{paymentMethods.length &&
                    paymentMethods.map((pm, index) => {
                    	const isActive = activeMethod === pm;
                    	return (
                    		<ListGroup.Item key={index} id={pm.id}>
                    			<Row className="justify-content-between px-2">
                    				<Col xs={8}>{pm.method}</Col>
                    				<Col xs={4} md={2} className="d-flex justify-content-around p-0">
                    					<button
                    						onClick={() => handleSelectMethod(pm)}
                    						className="action-button"
                    					>
                    						<FaEye className={isActive ? 'icon-active' : 'icon-inactive'} />
                    					</button>

                    					<button
                    						disabled={pm.method.toLowerCase() === 'mercadopago'}
                    						onClick={() => handleSelectDeletion(pm)}
                    						className="action-button"
                    					>
                    						<FaTrash></FaTrash>
                    					</button>
                    				</Col>
                    			</Row>
                    		</ListGroup.Item>
                    	);
                    })}
								</ListGroup>
							</Col>
						</Row>
					</Col>
					{activeMethod && (
						<Col xs={11} md={6} className="mt-2">
							<div id="method-data-container">
								<Row className="mb-3 row-align-left">
									<Col xs={12}>
										<div className="data-field">
											<label className="data-label">Nombre del método</label>
											<p className="data-value">{activeMethod.method}</p>
										</div>
									</Col>
								</Row>
								<Row className="mb-3 row-align-left">
									<Col xs={12}>
										<div className="data-field">
											<label className="data-label">Detalles</label>
											<p className="data-value">{activeMethod.details || 'No details available'}</p>
										</div>
									</Col>
								</Row>
								<Row className="align-items-center mb-3 row-align-left">
									<Col xs={8}>
										<span className="field-label">Activo</span>
									</Col>
									<Col xs={2}>
										<Form.Check
											className="pe-0"
											checked={activeMethod.active}
											readOnly
										/>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={handleEdit}>
											<FaPen />
										</button>
									</Col>
								</Row>
								<Row className="align-items-center mb-3 row-align-left">
									<Col xs={8}>
										<span className="field-label">Adjuntar comprobante</span>
									</Col>
									<Col xs={2}>
										<Form.Check
											className="pe-0"
											checked={activeMethod.uploadAttachment}
											readOnly
										/>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={handleEdit}>
											<FaPen />
										</button>
									</Col>
								</Row>
								<Row className="align-items-center row-align-left">
									<Col xs={8}>
										<span className="field-label">% Comisión</span>
									</Col>
									<Col xs={2}>
										<span className="field-value">{activeMethod.chargePercent ? `${activeMethod.chargePercent}%` : '0%'}</span>
									</Col>
									<Col xs={2} className="text-end">
										<button className="pen-edit-method" onClick={handleEdit}>
											<FaPen />
										</button>
									</Col>
								</Row>
							</div>
						</Col>
      
          
					)}
				</Row>
			</div>
		</>
	);
}
