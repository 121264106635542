import moment from 'moment';

export default class DiscountCode {
	constructor(_id, code, discountPercent, dateFrom, dateTo, products) {
		this._id = _id;
		this.code = code;
		this.discountPercent = discountPercent;
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
		this.products = products;
	}
	static create(obj) {
		return new DiscountCode(
			obj?._id,
			obj.code,
			obj.discountPercent,
			obj.dateFrom,
			obj?.dateTo,
			obj?.products || []
		);
	}
	static createEmpty() {
		return new DiscountCode('', '', '', '', '', []);
	}
	isValidCode() {
		const alphanumericSixCharRegex = /^[a-zA-Z0-9]{6}$/;
		return alphanumericSixCharRegex.test(this.code);
	}

	addProduct(productId) {
		if (!this.products.includes(productId)) {
			this.products.push(productId);
		}
	}

	removeProduct(productId) {
		this.products = this.products.filter(id => id !== productId);
	}
	/**
     * 
     * @returns {Object} - Object with the errors found in the discount
     */
	validate() {
		return { ...this.getDateErrors(), ...this.getDiscountErrors(), ...this.getProductsErrors()};
	}

	isActive() {
		const { dateFrom, dateTo } = this;
		const today = moment().startOf('day');
		const from = moment(dateFrom).startOf('day');
		const to = moment(dateTo).startOf('day');
		return dateFrom && dateTo && today.isBetween(from, to);
	}
	getProductsErrors() {
		const { products } = this;
		const errors = {};
		if (products.length === 0) {
			errors.noProducts = 'Debe seleccionar al menos un producto';
		}
		return errors;
	}
	getDateErrors() {
		const { code, dateFrom, dateTo } = this;
		const errors = {};
		const from = moment(dateFrom).startOf('day');
		const to = moment(dateTo).startOf('day');

		// Validate code
		if (code.length !== 6 || !/^[a-zA-Z0-9]+$/.test(code)) {
			errors.code = 'El código debe tener 6 caracteres alfanuméricos';
		}

		// Validate dateFrom and dateTo
		if (dateFrom && !from.isValid()) {
			errors.dateFrom = 'Fecha de inicio inválida';
		}

		if (dateTo && !to.isValid()) {
			errors.dateTo = 'Fecha de fin inválida';
		}

		if (dateFrom && dateTo && from.isAfter(to)) {
			errors.dateRange = 'La fecha de inicio no puede ser posterior a la fecha de fin';
		}
		return errors;
	}

	getDiscountErrors() {
		const { discountPercent } = this;
		const errors = {};
		if (discountPercent < 1 || discountPercent > 100) {
			errors.discountPercent = 'El porcentaje de descuento debe estar entre 1 y 100';
		}
		return errors;
	}
}

