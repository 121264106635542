import axios from '../restClient';

const BASE_URI = '/utils';
class UtilsService {
	async getIFrame() {
		const res = await axios.get(`${BASE_URI}/iframe`);
		return res;
	}
	async getPaymentMethod(method) {
		const res = await axios.get(`${BASE_URI}/paymentMethods/${method}`);
		return res;
	}
	async usesShipping() {
		const res = await axios.get(`${BASE_URI}/usesShipping`);
		return res;
	}
	async listPaymentMethods() {
		const res = await axios.get(`${BASE_URI}/paymentmethods`);
		return res;
	}

	async createMethod({method, details, active, uploadAttachment, chargePercent, usesPaymentGateway}) {
		const res = await axios.post(`${BASE_URI}/paymentmethods`, {
			method,
			details,
			active,
			uploadAttachment,
			chargePercent,
			usesPaymentGateway,
		});
		return res;
	}
	async editMethod(id, {method, details, active, uploadAttachment, chargePercent, usesPaymentGateway}) {
		const res = await axios.put(`${BASE_URI}/paymentmethods/${id}`, {
			method,
			details,
			active,
			uploadAttachment,
			chargePercent,
			usesPaymentGateway
		});
		return res;
	}

	async deleteMethod(id) {
		const res = await axios.delete(`${BASE_URI}/paymentmethods/${id}`);
		return res;
	}
}
export default new UtilsService();
