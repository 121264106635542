import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import QuantityInputContainer from '../../components/QuantityInputContainer/QuantityInputContainer';
import MessageBox from '../../components/MessageBox/MessageBox';



const AddToCartButton = (props) => {
	const {
		product,
		loadedVariant,
		options,
		optionsOfVariant,
	} = props;
	const renderButtonRow = () => {
		let cmpToRender = null;
		if(product && (!product.hasVariants || options.length > 0 ) ) {
			const variantIsAvailable = loadedVariant && loadedVariant.countInStock > 0 && loadedVariant.active;
			const variantIsBeingSelected = !loadedVariant && options.length !== optionsOfVariant.length;
			const variantSelectionIsAvailable = product.active && (variantIsAvailable || variantIsBeingSelected);
			const productSelectionIsAvailable = !product.hasVariants && product.active && product.countInStock > 0;
            
			if (product.hasVariants ? variantSelectionIsAvailable : productSelectionIsAvailable) {
				cmpToRender = <ButtonRow {...props}/>;
			} else {
				cmpToRender = <RowWithErrorMessage {...props} />;
			}
		}
		return cmpToRender;
	};
	return renderButtonRow();
};

export default AddToCartButton;

function ButtonRow(props) {
	const {
		product,
		loadedVariant,
		options,
		numOfProd,
		cartHandler,
		quantityError,
		addToCartHandler,
		item
	} = props;
	return <Row className="justify-content-start align-items-center ps-3">
		<Col xs={12}>
			<QuantityInputContainer value={numOfProd} onChangeValue={cartHandler}
				stock={product.hasVariants && options.length ? (loadedVariant && loadedVariant.countInStock) : product.countInStock}
				disabled={product.hasVariants && options.length > 0 && !loadedVariant} />
		</Col>
		<Col xs={12} className="quantity-message-container">
			{quantityError}
		</Col>
		<Col xs={12} style={{ margin: '5% auto' }}>
			<Button
				onClick={addToCartHandler}
				className="product-button"
				variant="primary"
				disabled={quantityError}
			>
				<h2>
					{item
						? 'Actualizar carrito'
						: 'Agregar al Carrito'}
				</h2>
			</Button>
		</Col>
	</Row>;
}
const RowWithErrorMessage = (props) => {
	const {
		product,
		loadedVariant,
	} = props;
	let msg = '';
	if(product.hasVariants && product.active) {
		msg = !loadedVariant || !loadedVariant.active ? 'Variante no disponible' : 'Variante sin stock';
	} else {
		msg = !product.active ? 'Producto no disponible' : 'Producto sin stock';
	}
	return <Row className="my-2">
		<MessageBox cols={12} variant="danger">
			{msg}
		</MessageBox>
	</Row>;
};