import axios from '../restClient';
import { getParamsObj } from '../utils';
const BASE_URI = '/flap';

class FlapService {
	async list(searchParams) {
		const query = getParamsObj(searchParams);
		const res = await axios.get(BASE_URI, {
			params : query
		});
		return res;
	}

	async listPendings() {
		const res = await axios.get(`${BASE_URI}/pending/`);
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async create(field, currentValue, newValue, model, name, recordId ) {
		const res = await axios.post(BASE_URI, {
			field, currentValue, newValue, model, name, recordId 
		});
		return res;
	}

	async acceptChange(id, comment) {
		const res = await axios.post(`${BASE_URI}/accept/${id}`, { comment });
		return res;
	}

	async rejectChange(id, comment) {
		const res = await axios.post(`${BASE_URI}/reject/${id}`, { comment });
		return res;
	}


}
export default new FlapService();