import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';
import ImageCropper from '../../../ImageCropper/ImageCropper';
import { makeVariantName } from '../../../../utils';
import ButtonSecondaryTranslucidHover from '../../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import { Button } from 'react-bootstrap';
import _productVariant from '../../../../services/productVariant.service';
import ImageCropModal from '../../../ImageCropModal/ImageCropModal';
import { TbCameraPlus } from 'react-icons/tb';


export default function VariantConfiguration({ variant, setVariantValue, onSuccess }) {
	const [imgSrc, setImgSrc] = useState(null);
	const inputFileRef = useRef(null);

	const saveImage = async (image) => {
		if (!image || !image.blob) {
			toast.error('Error: No se proporcionó una imagen válida');
			return;
		}

		const file = image.blob;
		const formData = new FormData();
		
		if (!formData) {
			toast.error('Error: No se pudo crear el FormData');
			return;
		}

		let r = Math.random().toString(36).substring(7);
		try {
            const variantName = makeVariantName(variant);
            const fileName = variantName.replace(/[^a-zA-Z0-9]/g, '') + r;
			formData.append('file', file, fileName);
			const response = await _productVariant.uploadImage(variant._id, formData);
			toast.success('Imagen guardada.');
			setVariantValue(variant._id, 'image', response.data, true);
			setImgSrc(null);
		} catch (err) { 
			console.error(err);
			toast.error('Error cargando imagen, intente nuevamente.');
		}
	};
	const deleteImage = async () => {
		try {
			await _productVariant.deleteImage(variant.image._id);
			setVariantValue(variant._id, 'image', null, true);
			toast.success('Imagen eliminada');
			setImgSrc(null);
		} catch (err) {
			console.error(err);
			toast.error('Error eliminando imagen.');
		}
	};
	const updateVariants = async () => {
		try {
			await _productVariant
				.edit(variant._id, variant.countInStock, variant.active, variant.price);
			toast.success('Variante actualizada.');
			onSuccess();
		} catch (err) {
			console.error(err);
			toast.error('Error actualizando variante.');
		}
	};
	if (!variant || !setVariantValue) {
		console.error('You must pass a variant to bind with the inputs and a setter to change the variant value ');
		return;
	}
	const onChangeImage = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				const img = new Image();
				img.src = reader.result.toString();
				img.onload = () => {
					if (img.width >= 400 || img.height >= 400) {
						setImgSrc(reader.result.toString());
					} else {
						toast.error('La resolución de la imagen debe ser de al menos 400x400.');   
					}
				};
			});
			reader.readAsDataURL(event.target.files[0]);
		}
		event.target.value = '';
	};
	const handleNewFile = () => {
		inputFileRef.current.click();
	};
    
	return (
		<Row>
			<input type="file" className='d-none' ref={inputFileRef} onChange={onChangeImage} />
			<ImageCropModal show={imgSrc} onHide={() => setImgSrc(null)} onCrop={saveImage} imgSrc={imgSrc} setImgSrc={setImgSrc} />
			<Col md={6}>
				{variant.image ?
					< figure >
						<img src={variant.image.fileLink} />
					</figure>
					:
					<>
						<button className='image-cropper-trigger' onClick={handleNewFile}>
							<TbCameraPlus size={50} color='rgba(63, 62, 62, 0.9)' />
							<div>Nueva foto</div>
						</button>
						<p className= "m-0 w-100 text-center fw-semibold text-danger"> Resolución mínima de imagen: 400x400</p>
					</>
				}
			</Col>
			<Col md={6} className='row variant-input-row p-1'>
				<Col xs={12}>
					<FloatingLabel label="Precio">
						<Form.Control placeholder='Nombre' value={variant.price} onChange={(e) => setVariantValue(variant._id, 'price', e.target.value)} />
					</FloatingLabel>
				</Col>
				<Col xs={12}>
					<FloatingLabel label="Stock">
						<Form.Control placeholder='Nombre' value={variant.countInStock} onChange={(e) => setVariantValue(variant._id, 'countInStock', e.target.value)} />
					</FloatingLabel>
				</Col>
				<Col xs={12}>
					<FloatingLabel label="Activo">
						<Form.Check placeholder='Nombre' checked={variant.active} onChange={(e) => setVariantValue(variant._id, 'active', e.target.checked)} />
					</FloatingLabel>
				</Col>
			</Col>
			<div className='d-flex justify-content-around mt-4'>
				<ButtonSecondaryTranslucidHover disabled={!variant.image} onClick={deleteImage}>
                    Eliminar foto
				</ButtonSecondaryTranslucidHover>
				<Button onClick={updateVariants}>
                    Guardar
				</Button>
			</div>
		</Row>
	);
}
