import React from 'react';
import './AdminMarketing.css';
import { FaBullhorn } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import AdminBanners from '../AdminBanners/AdminBanners';
import DiscountCodes from '../../components/DiscountCodes/DiscountCodes.jsx';
import { SCOPES } from '../../permission-utils/scopes.js';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';

export default function AdminMarketingScreen() {
	return (
		<div id = "admin-config-screen" className="admin-con p-3">
			<h1 className="text-right section-title">
				<FaBullhorn></FaBullhorn>Marketing
			</h1>
			<div className="container config-items">
				<Accordion>
					<PermissionWrapper fn={'banners'} scopes={[SCOPES.canView]}>
						<Accordion.Item eventKey="0">
							<Accordion.Header>Banners</Accordion.Header>
							<Accordion.Body>
								<AdminBanners></AdminBanners>
							</Accordion.Body>
						</Accordion.Item>
					</PermissionWrapper>
					<PermissionWrapper fn={'discountCodes'} scopes={[SCOPES.canView]}>
						<Accordion.Item eventKey="1">
							<Accordion.Header>Códigos de descuento</Accordion.Header>
							<Accordion.Body>
								<DiscountCodes/>
							</Accordion.Body>
						</Accordion.Item>
					</PermissionWrapper>
				</Accordion>
			</div>
		</div>
	);
}
