import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import './DynamicForm.css';
/**
 * DynamicForm Component
 * 
 * This component renders a form based on a given template.
 * 
 * Props:
 * @param {function} onSubmit - Function to call when the form is submitted.
 * @param {object} formTemplate - Template describing the structure and fields of the form.
 * @param {object} initialState - Initial state of the form fields.
 * @param {string} submitButtonText - Text to display on the submit button.
 * @param {string} cancelButtonText - Text to display on the cancel button.
 * @param {function} onCancel - Function to call when the cancel button is clicked.
 * 
 * Example:
 * <DynamicForm
 *   onSubmit={handleFormSubmit}
 *   formTemplate={formTemplate}
 *   initialState={initialFormState}
 *   submitButtonText="Save"
 *   cancelButtonText="Cancel"
 *   onCancel={handleCancel}
 * />
 */

const DynamicForm = ({ 
	onSubmit, 
	formTemplate, 
	initialState, 
	submitButtonText = 'Submit',
	cancelButtonText = 'Cancel',
	onCancel
}) => {
	const [formState, setFormState] = useState(new Map());
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		initializeForm();
	}, [initialState]);

	const initializeForm = () => {
		try {
			if (initialState && typeof initialState === 'object') {
				// Convert initialState to new structure if it's not already
				const structuredState = Object.entries(initialState).reduce((acc, [key, value]) => {
					acc[key] = {
						value: value?.value ?? value,
						disabled: value?.disabled ?? false
					};
					return acc;
				}, {});
				setFormState(new Map(Object.entries(structuredState)));
			} else {
				// Initialize with empty values and disabled states from template
				const defaultState = Object.keys(formTemplate).reduce((acc, key) => {
					const defaultValue = formTemplate[key].type === 'typeahead' ? [] : 
									   formTemplate[key].type === 'boolean' ? false : '';
					acc[key] = {
						value: defaultValue,
						disabled: formTemplate[key].disabled ?? false
					};
					return acc;
				}, {});
				setFormState(new Map(Object.entries(defaultState)));
			}
		} catch (error) {
			console.error('Error initializing form:', error);
		}
	};

	const validateForm = () => {
		const newErrors = {};
		Object.keys(formTemplate).forEach(key => {
			const fieldState = formState.get(key);
			const value = fieldState?.value;
			const field = formTemplate[key];

			if (field.required && (!value || (Array.isArray(value) && value.length === 0))) {
				newErrors[key] = `${field.label} es requerido`;
			}

			if (field.validator) {
				const validationError = field.validator(value);
				if (validationError) {
					newErrors[key] = validationError;
				}
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleFormChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormState(prev => {
			const newState = new Map(prev);
			const currentField = prev.get(name) || { disabled: false };
			newState.set(name, {
				...currentField,
				value: type === 'checkbox' ? checked : value
			});
			return newState;
		});
		
		if (errors[name]) {
			setErrors(prev => ({ ...prev, [name]: null }));
		}
	};

	const handleChangeTypeahead = (data, key) => {
		setFormState(prev => {
			const newState = new Map(prev);
			const currentField = prev.get(key) || { disabled: false };
			newState.set(key, {
				...currentField,
				value: data
			});
			return newState;
		});

		if (errors[key]) {
			setErrors(prev => ({ ...prev, [key]: null }));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		
		if (!validateForm()) {
			return;
		}

		try {
			setLoading(true);
			// Convert formState to object with just values for submission
			const formData = Array.from(formState.entries()).reduce((acc, [key, field]) => {
				acc[key] = field.value;
				return acc;
			}, {});
			await onSubmit(formData);
		} catch (error) {
			console.error('Form submission error:', error);
			setErrors(prev => ({
				...prev,
				submit: 'Error al enviar el formulario. Por favor, inténtelo de nuevo.'
			}));
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form onSubmit={handleSubmit} className="dynamic-form">
			{errors.submit && (
				<div className="alert alert-danger">{errors.submit}</div>
			)}
			
			{Object.keys(formTemplate).map((key) => {
				const { type, label, options, labelKey, required } = formTemplate[key];
				const fieldState = formState.get(key) || { value: '', disabled: false };
				const { value, disabled } = fieldState;

				if (type === 'typeahead') {
					return (
						<Form.Group key={key} className="mb-3">
							<Form.Label>{label}{required && ' *'}</Form.Label>
							<Typeahead
								id={`typeahead-${key}`}
								multiple
								options={options?.filter(opt => 
									!value?.some(v => v[labelKey] === opt[labelKey] && v._id === opt._id)
								) || []}
								placeholder={label}
								selected={value || []}
								labelKey={labelKey}
								onChange={(data) => handleChangeTypeahead(data, key)}
								isInvalid={!!errors[key]}
								disabled={disabled}
							/>
							{errors[key] && (
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors[key]}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					);
				}

				if (type === 'boolean') {
					return (
						<Form.Group key={key} className="mb-3">
							<div className="d-flex align-items-center">
								<Form.Label className="mx-2 mb-0">
									{label}
								</Form.Label>
								<Form.Check
									type="switch"
									id={key}
									name={key}
									checked={value}
									onChange={handleFormChange}
									isInvalid={!!errors[key]}
									disabled={disabled}
								/>
							</div>
							{errors[key] && (
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors[key]}
								</Form.Control.Feedback>
							)}
						</Form.Group>
					);
				}
				if(type === 'textarea') {
					return (
						<FloatingLabel
							key={key}
							controlId={key}
							label={`${label}${required ? ' *' : ''}`}
							className="mb-3"
						>
							<Form.Control
								as="textarea"
								name={key}
								value={value || ''}
								onChange={handleFormChange}
								isInvalid={!!errors[key]}
								disabled={disabled}
							/>
							{errors[key] && (
								<Form.Control.Feedback type="invalid" className="d-block">
									{errors[key]}
								</Form.Control.Feedback>
							)}
						</FloatingLabel>
					);
				}

				return (
					<FloatingLabel
						key={key}
						controlId={key}
						label={`${label}${required ? ' *' : ''}`}
						className="mb-3"
					>
						{type === 'select' ? (
							<Form.Select
								name={key}
								value={value || ''}
								onChange={handleFormChange}
								isInvalid={!!errors[key]}
								disabled={disabled}
							>
								<option value="">Seleccionar {label}</option>
								{options?.map(opt => (
									<option key={opt.value} value={opt.value}>
										{opt.label}
									</option>
								))}
							</Form.Select>
						) : (
							<Form.Control
								type={type}
								name={key}
								placeholder={label}
								value={value || ''}
								onChange={handleFormChange}
								isInvalid={!!errors[key]}
								disabled={disabled}
							/>
						)}
						{errors[key] && (
							<Form.Control.Feedback type="invalid">
								{errors[key]}
							</Form.Control.Feedback>
						)}
					</FloatingLabel>
				);
			})}

			<div className="d-flex justify-content-around gap-2 mt-4">
				{onCancel && (
					<button 
						type="button" 
						className="btn btn-secondary" 
						onClick={onCancel}
						disabled={loading}
					>
						{cancelButtonText}
					</button>
				)}
				<button 
					type="submit" 
					className="btn btn-primary"
					disabled={loading}
				>
					{loading ? 'Guardando...' : submitButtonText}
				</button>
			</div>
		</Form>
	);
};

DynamicForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	formTemplate: PropTypes.object.isRequired,
	initialState: PropTypes.object,
	submitButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	onCancel: PropTypes.func
};

export default DynamicForm;
