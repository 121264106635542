import React from 'react';
import { Modal } from 'react-bootstrap';
import NewImageCropper from '../NewImageCropper/NewImageCropper';
import './ImageCropModal.css';
const ImageCropModal = ({ imgSrc, setImgSrc, onCrop, onHide, show }) => {

    return (
        <>
            <Modal backdrop='static' show={show} onHide={onHide} dialogClassName='image-crop-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Ajusta la imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className='modal-cropper-container'>
                        <NewImageCropper imgSrc={imgSrc} setImgSrc={setImgSrc} onExit = {onHide} onCrop={onCrop} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageCropModal;