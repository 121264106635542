import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { useContext } from 'react';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import './AdminProducts.css';
import EditStockModal from '../../components/EditStockModal/EditStockModal.jsx';
import DynamicListTable from '../../components/DynamicTable/DynamicListTable.js';
import PreviewProductModal from '../../components/PreviewProductModal/PreviewProductModal.jsx';
import _products from '../../services/product.service.js';
import FormFilter from '../../components/FormFilter/FormFilter.jsx';
import EditStockPriceModal from '../../components/EditStockPriceModal/EditStockPriceModal.jsx';
import UpdatePricesModal from '../../components/UpdatePricesModal/UpdatePricesModal.jsx';
import FlapService from '../../services/flap.service.js'; 
import useQueryFilters from '../../hooks/useQueryFilters.jsx';
import { LuLayoutList } from 'react-icons/lu';
import VariantGenerationWizard from '../VariantGenerationWizard/VariantGenerationWizard.jsx';
import { toCamelCase } from '../../utils';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			products: action.payload.products,
			countProducts: action.payload.countProducts,
			loading: false,
		};
	case 'UPDATE_PRODUCTS':
		return {
			...state, 
			products: action.payload
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload.products };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return {
			...state,loadingUpdate: false      };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false};
	case 'UPDATE_PRODUCT':
		return { ...state, products: action.payload};
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'EDIT_RESET':
		return { ...state, successEdit: false};
	case 'OPEN_PREVIEW_MODAL':
		return { ...state, previewModalIsOpen: true, previewProduct: action.payload};
	case 'CLOSE_PREVIEW_MODAL':
		return { ...state, previewModalIsOpen: false, previewProduct: null};
	case 'OPEN_EDIT_PRICESTOCK_MODAL':
		return { ...state, editStockPriceModalIsOpen: true, previewProduct: action.payload};
	case 'CLOSE_EDIT_PRICESTOCK_MODAL':
		return { ...state, editStockPriceModalIsOpen: false, previewProduct: null};
	default:
		return state;
	}

};


function AdminProducts() {
	const [reducerState, dispatch,] = useReducer(reducer, {
		products: [],
		countProducts: 0,
		loading: true,
		error: '',
		previewProduct: null,
		previewModalIsOpen: false,
		successEdit: false,
		editStockPriceModalIsOpen: false,
	});

	const {loading, error, previewProduct, previewModalIsOpen, editStockPriceModalIsOpen,
		products, successEdit, successDelete,countProducts } = reducerState;

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [showUpdatePricesModal, setShowUpdatePricesModal] = useState(false);

	const editHandler = (product) => navigate('/AdminScreen/AdminProducts/crud/' + product._id);
	const openPreviewModal = (product) => dispatch({ type: 'OPEN_PREVIEW_MODAL', payload: product });
	const closePreviewModal = () => dispatch({ type: 'CLOSE_PREVIEW_MODAL' });
	const openEditStockPriceModal = (product) => dispatch({ type: 'OPEN_EDIT_PRICESTOCK_MODAL', payload: product });
	const closeEditStockPriceModal = () => dispatch({ type: 'CLOSE_EDIT_PRICESTOCK_MODAL' });

	const { submitFilters, setPage, setFieldsAndSubmit,
		fields, setFields } = useQueryFilters({ baseUrl: '/AdminScreen/AdminProducts/search', resizeWidth: 400 });
	const getTotalCurrentCount = () => fields.status ? reducerState[toCamelCase(products.status) + 'Count'] : countProducts;

	const [selectedProducts, setSelectedProducts] = useState([]);
	const [openStockModal, setOpenStockModal] = useState(false);
	const [showVariantGenerationWizard , setShowVariantGenerationWizard] = useState(false);

	useEffect(() => {
		const newSearchParams = new URLSearchParams();
		Object.entries(fields).forEach(([key, value]) => {
			if (value !== undefined && value !== null && value !== '') {
				newSearchParams.append(key, value);
			}
		});
		const newSearch = newSearchParams.toString();
		const currentSearch = search.replace('?', '');
		if (newSearch !== currentSearch) {
			navigate(`?${newSearch}`, { replace: true });
		}
	}, [fields]);

	useEffect(() => {
		const currTotalCount = getTotalCurrentCount();
		if (parseInt(fields.page) > Math.ceil(currTotalCount / fields.pageSize)) {
			setPage(1); // Reset page if it's out of bounds
		}
	}, [fields]);

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else if (successEdit) {
			dispatch({ type: 'EDIT_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, successEdit, search, dispatch]);



	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await _products.adminSearch(searchParams);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
		}
	};


	const updateStockHandler = async (productId, stock) => {
		try {
			await _products.updateProductStock(productId, stock);

			const productIndex = products.findIndex(({ _id }) => _id === productId);
			if(productIndex > -1) {
				const productsCopy = [...products];
				productsCopy[productIndex].countInStock = stock;
				dispatch({ type: 'UPDATE_PRODUCTS', payload: productsCopy });
			}
			setOpenStockModal(false);
			toast.success('Stock actualizado.');
		} catch (ex) {
			toast.error('Error. No se pudo actualizar el stock.');
			console.error(ex);
		}
	};

	async function deleteHandler(product) {
		if (window.confirm('Seguro que quieres borrar este producto?')) {
			try {
				await _products.delete(product._id);
				toast.success('Producto eliminado correctamente');
				dispatch({ type: 'DELETE_SUCCESS' });
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(err);
				toast.error('Error. No se pudo borrar el producto');
			}
		}
	}


	const handleSelectedRowsChange = (selectedItems) => {
		setSelectedProducts(selectedItems);
	};


	const handleShowUpdatePricesModal = () => {
		if(selectedProducts.length){
			setShowUpdatePricesModal(true);
		} else {
			toast.warn('Debes seleccionar al menos un producto');
		}
	};

	const handleCloseUpdatePricesModal = () => {
		setShowUpdatePricesModal(false);
	};


	const handleUpdatePrices = async (updatedProducts) => {
		dispatch({ type: 'UPDATE_REQUEST' });
		try {
			const promises = updatedProducts.map(async (product) => {
				await FlapService.create(
					'price',
					product.price,
					product.newPrice,
					'Product',
					product.name,
					product._id
				);
			});
			await Promise.all(promises); // Create flaps for each product
			dispatch({ type: 'UPDATE_SUCCESS' });
			await fetchData();
			toast.success('Solicitud de actualización de precios creada.');
			setSelectedProducts([]);
		} catch (err) {
			dispatch({ type: 'UPDATE_FAIL' });
			console.error(err);
		}
		// }
	};
	const handleShowVariantGenerationWizard = () => {
		const anyOfTheProductHaveVariants = selectedProducts.some(p => p.hasVariants);
		if (selectedProducts.length > 1 && !anyOfTheProductHaveVariants) {
			setShowVariantGenerationWizard(true);
		} else if (anyOfTheProductHaveVariants){ 
			toast.error('Debes seleccionar productos sin variantes');
		} else {
			toast.error('Debes seleccionar al menos dos productos para generar variantes');
		}
	};
	const updateProduct = (data, id) => {
		const productsCopy = [...products];
		const productIndex = productsCopy.findIndex((product) => product._id === id);
		productsCopy[productIndex] = { ...productsCopy[productIndex], ...data };
		dispatch({ type: 'UPDATE_PRODUCT', payload: productsCopy });
	};

	const [actions] = useState({
		editHandler: {
			handler: editHandler,
			label: 'Editar'
		},
		deleteHandler: {
			handler: deleteHandler,
			label: 'Eliminar'
		},
		previewHandler: {
			handler: openPreviewModal,
			label: 'Vista previa'
		},
		editStockPriceHandler: {
			handler: openEditStockPriceModal,
			label: 'Editar stock/precio'
		}
	});


	return (
		<>
			<UpdatePricesModal
				show={showUpdatePricesModal}
				handleClose={handleCloseUpdatePricesModal}
				handleUpdatePrices={handleUpdatePrices}
				selectedProducts={selectedProducts}
			/>
			<Helmet>
				<title>Lista de productos</title>
			</Helmet>
			<VariantGenerationWizard show={showVariantGenerationWizard} close={() => setShowVariantGenerationWizard(false)}
				selectedProducts={selectedProducts} />
			<PreviewProductModal closeHandler={closePreviewModal} show={previewModalIsOpen} product={previewProduct} />
			<EditStockPriceModal userInfo = {userInfo} closeHandler={closeEditStockPriceModal} 
				updateHandler={updateProduct} show={editStockPriceModalIsOpen} product={previewProduct} />
			<EditStockModal show={openStockModal} product={previewProduct}
				close={() => setOpenStockModal(false)}
				onSuccess={updateStockHandler} />
			<div id="admin-products-container" className="admin-con p-1 pt-2 pe-3">
				<h1 className="text-right section-title">
					<LuLayoutList  className='mb-3'/> 
					<span>
					Lista de productos
					</span>
				</h1>
				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<div className='admin-list-container'>
						<FormFilter fields={fields} setFields={setFields} setFieldsAndSubmit = {setFieldsAndSubmit} 
							submitFilters = {submitFilters} showUpdatePricesModal = {handleShowUpdatePricesModal}
							showVariantGenerationWizard={handleShowVariantGenerationWizard}/>
						{products.length > 0 ?
							<>
								<DynamicListTable
									dataName="products"
									data={products}
									loading={loading}
									error={error}
									columnInfo={[
										{ name: { type: 'string', label: 'Nombre', link: (item) => `/product/${item._id}` } },
										{ brand: { type: 'string', label: 'Marca' } },
										{ price: { type: 'currency', label: 'Precio' } },
										{ 'category.name': { type: 'string', label: 'Categoría' } },
										{ countInStock: { type: 'number', label: 'Stock' } },
										{ active: { type: 'boolean', label: 'Activo' } }
									]}
									count={countProducts}
									page={fields.page}
									pageSize={fields.pageSize}
									onPageChange={(newPage) => {
										setFields(prev => ({...prev, page: newPage}));
									}}
									actionButtons={actions}
									showCheckboxColumn={true}
									selectedItems={selectedProducts}
									setSelectedItems={setSelectedProducts}
									onChangeSelectedRows={handleSelectedRowsChange}
									frontPaginator={false}
								/>
							</>
							: <MessageBox className={'my-2'}>No se encontraron productos</MessageBox>
						}
					</div>
				)}
			</div>
		</>
	);
}

export default AdminProducts;
