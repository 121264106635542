import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DynamicForm from '../DynamicForm/DynamicForm';
import './DynamicFormModal.css';	

export default function DynamicFormModal({show, handleClose, title = '', 
	 onSubmit, formTemplate, initialState,
	submitButtonText, cancelButtonText}) {
	return (
		<Modal show={show} onHide={handleClose} animation={false} dialogClassName='dynamic-form-modal'>
			<Modal.Header>
				<Modal.Title>
					{title}
				</Modal.Title>
			</Modal.Header>
			{ show && 
				<DynamicForm
					onSubmit={onSubmit}
					formTemplate={formTemplate}
					initialState={initialState}
					submitButtonText={submitButtonText}
					cancelButtonText={cancelButtonText}
					onCancel={handleClose}
				/>}
		</Modal>
	);
}
