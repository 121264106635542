import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { getError } from '../../utils';
import { Store } from '../../Store';
import { FaPlusCircle, FaUsers } from 'react-icons/fa';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { SCOPES } from '../../permission-utils/scopes.js';
import PermissionWrapper from '../../permission-utils/PermissionWrapper.jsx';
import AddUser from '../../components/AddUser/AddUser.jsx';
import DynamicListTable from '../../components/DynamicTable/DynamicListTable.js';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal/DeleteConfirmationModal.js';
import _users from '../../services/user.service.js';
import useQueryFilters from '../../hooks/useQueryFilters.jsx';
import ExportToExcelButton from '../../components/ExportData/ExportToExcel.js';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			users: action.payload.users,
			totalCount: action.payload.userCount,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminUsers() {
	const [{ loading, error, users, totalCount }, dispatch] = useReducer(
		reducer,
		{
			users: [],
			loading: true,
			totalCount: 0,
			error: '',
			itemQuantity: 0,
		}
	);

	const { state } = useContext(Store);
	const { userInfo } = state;
	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [creatingUser, setCreatingUser] = useState(false); 
	const [editingUser, setEditingUser] = useState(false);
	const { search } = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(search);

	const { setPage, fields, setFields } = 
		useQueryFilters({ baseUrl: '/AdminScreen/AdminUsers', resizeWidth: 400 });

	useEffect(() => {
		const newSearchParams = new URLSearchParams();
		Object.entries(fields).forEach(([key, value]) => {
			if (value !== undefined && value !== null && value !== '') {
				newSearchParams.append(key, value);
			}
		});
		const newSearch = newSearchParams.toString();
		if (newSearch !== search) {
			navigate(`?${newSearch}`, { replace: true });
		}
	}, [fields, navigate, search]);

	useEffect(() => {
		if (parseInt(fields.page) > Math.ceil(totalCount / fields.pageSize)) {
			setPage(1);
		}
	}, [fields, totalCount, setPage]);

	const fetchData = async () => {
		try {
			dispatch({ type: 'FETCH_REQUEST' });
			const { data } = await _users.list(searchParams);
			dispatch({ type: 'FETCH_SUCCESS', payload: data });
			setSelectedUser(null);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			console.error(err);
			toast.error('No se pudo obtener a los usuarios');
		}
	};
	useEffect(() => {
		fetchData();
	}, [userInfo, editingUser, creatingUser,search]);

	const submitHandler = async (user, createUser) => {
		if(createUser){
			setCreatingUser(true);
			try {
				await _users.create(user);
				toast.success('Usuario creado con éxito');
        
			} catch (err) {
				console.error('err',err);
				toast.error(getError(err));
			}
			setCreatingUser(false);

		} else{
			try {
				setEditingUser(true);
				await _users.editById(user);
				toast.success('Usuario actualizado con éxito');
      
			} catch (err) {
				toast.error(getError(err));
			}
			setEditingUser(false);

		}
		setSelectedUser(null);
		setShowAddUserModal(false);
	};

	const deleteHandler = (user) => {
		setSelectedUser(user);
		setShowDeleteModal(true);
	};

	const handleDeleteUser = async () => {
		try {
			await _users.delete(selectedUser._id);
			setShowDeleteModal(false);
			toast.success(`Usuario ${selectedUser.name} eliminado exitosamente`);
		} catch (error) {
			toast.error('Error al eliminar el usuario');
		}
	};

	const editHandler = (user) => {
		setSelectedUser(user);
		setShowAddUserModal(true);
	};

	const handleCloseModal = () => {
		setSelectedUser(null);
		setShowAddUserModal(false);
	};
	const verifyUser = async (user) => {
		try {
			await _users.adminVerify(user._id);
			toast.success('Usuario verificado');
			await fetchData();
		} catch (err) {
			console.error(err);
			toast.error(getError(err));
		}
	};
	const resetPassword = async (user) => {
		try {
			await _users.forgotPassword(user.email);
			toast.success('Correo de reinicio de contraseña enviado');
		} catch (err) {
			console.error(err);
			toast.error(getError(err));
		}
	};

	return (
		<>
			<Helmet>
				<title>Lista de usuarios</title>
			</Helmet>
			<AddUser
				close={()=> handleCloseModal()}
				show={showAddUserModal}
				onSuccess={submitHandler}
				userData={selectedUser}
			/>
			<DeleteConfirmationModal
				show={showDeleteModal}
				handleClose={() => setShowDeleteModal(false)}
				user={selectedUser}
				handleDelete={handleDeleteUser}
			/>
			<div id="admin-users-container" className="admin-con p-3">
				<h1 className="text-right section-title" style={{ marginBottom: '2.5%' }}>
					<FaUsers></FaUsers>Lista de usuarios
				</h1>
				<div className="row adminProdBtnRow align-items-center">
					<div className="col-lg-8 col-sm-12 col-md-12 search-bar-container"></div>
					<div className="col-lg-3 col-sm-12">
						<PermissionWrapper fn={'users'} scopes={[SCOPES.canCreate]}>
							<Button onClick={() => {
								setSelectedUser(null);
								setShowAddUserModal(true);}}>
								<FaPlusCircle className="pe-1"></FaPlusCircle> Agregar usuario
							</Button>
						</PermissionWrapper>
					</div>
					<ExportToExcelButton
						dataFetcherFunction={_users.list.bind(this,searchParams,true)} 
						data={users}
						dataName={'users'}
						overlayPlacement='top'
					/>
				</div>
				{loading || creatingUser || editingUser ? (
					<LoadingBox></LoadingBox>
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<Card>
						<DynamicListTable
							dataName="users"
							data={users}
							loading={loading}
							error={error}
							columnInfo={[
								{ name: { type: 'string', label: 'Nombre' } },
								{ email: { type: 'string', label: 'Email' } },
								{ role: { type: 'string', label: 'Rol' } },
								{ isVerified: { type: 'boolean', label: 'Verificado' } }
							]}
							count={totalCount}
							page={fields.page}
							pageSize={fields.pageSize}
							onPageChange={(newPage) => {
								setFields(prev => ({...prev, page: newPage}));
							}}
							actionButtons={{
								editHandler: {
									handler: editHandler,
									label: 'Editar'
								},
								deleteHandler: {
									handler: deleteHandler,
									label: 'Eliminar'
								},
								verifyUser: {
									handler: verifyUser,
									label: 'Verificar Usuario',
									restrictions: {
										status: 'unverified'
									}
								},
								resetPassword: {
									handler: resetPassword,
									label: 'Resetear Contraseña'
								}
							}}
							showCheckboxColumn={false}
							frontPaginator={false}
						/>
					</Card>
				)}
			</div>
		</>
	);
}

export default AdminUsers;
