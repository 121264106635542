/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Table,
	Dropdown,
	Form,
	Button,
	Modal,
	Row,
	Col
} from 'react-bootstrap';
import { FaSortUp, FaSortDown, FaFileExcel } from 'react-icons/fa';
import LoadingTableBox from '../LoadingTableBox';
import MessageBox from '../MessageBox/MessageBox';
import Pagination from '../Pagination/Pagination';
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';
import './DynamicListTable.css';
import DynamicTableField from './DynamicTableField';
import { IoTriangleOutline } from 'react-icons/io5';


const DynamicListTable = ({
	dataName, //DataName for customization
	data, //database
	loading, //for loading spinner
	error, //for Error messageBox
	columnInfo = [], // Array of column configurations with default empty array
	count, //for paginator if its from queries filter
	onPageChange, //for paginator if its from queries filter
	page, //for paginator if its from queries filter
	pageSize, //for paginator if its from queries filter
	handleSort, //for central lists, ordering function
	direction,
	sortColumn,
	actionButtons, //seeDetails,editHandler,seeAttachment and deleteHandler
	showCheckboxColumn, //first column with checkboxes
	frontPaginator, //frontPaginator, set to true if is only frontend 
	posts, //set postsPerPage if its front paginator
	exportOption, //export button for xls, with modal
	selectedFieldToExport, //default selectedField to Export
	useBasicActionButtons = false, //if true, use basic action buttons from getDefaultActions,
	selectedItems,
	setSelectedItems,
}) => {

	// Helper function to get default label based on action key
	const getDefaultLabel = (key) => {
		const labels = {
			seeDetails: 'Ver Detalles',
			editHandler: 'Editar',
			deleteHandler: 'Eliminar',
		};
		return labels[key] || key;
	};


	//data to show, column mappings
	const columnMap = {};
	const columns = [];
	const types = {};
	const links = {};
	const linkPaths = {};

	//data for export
	let columnInfoToShow = columnInfo || [];
	let columnInfoToExport = {};
	const columnMapExport = {};
	const columnsExport = [];
	const typesExport = {};

	// Only process columnInfo if it's an array and has items
	if (Array.isArray(columnInfoToShow) && columnInfoToShow.length > 0) {
		columnInfoToShow.forEach((col) => {
			const key = Object.keys(col)[0];
			if (col[key].toExport) {
				return;
			}
			columnMap[key] = col[key].label;
			columns.push(key);
			types[key] = col[key].type;
			links[key] = col[key].link;
			linkPaths[key] = col[key].linkPath;
		});

		if(exportOption){
			columnInfoToExport = columnInfo;
			columnInfoToExport.forEach((col) => {
				const key = Object.keys(col)[0];
				columnMapExport[key] = col[key].label;
				columnsExport.push(key);
				typesExport[key] = col[key].type;
			});
		}
	}

	//the formating for export only includes date, and boolean
	function getFormattedValueExport(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		let type = (typesExport[column]);
		for (const key of keys) {
			if (
				nestedValue &&
				nestedValue[key] !== undefined &&
				nestedValue[key] !== 0 &&
				nestedValue[key] !== null &&
				nestedValue[key] !== ''
			) {
				nestedValue = nestedValue[key];
			} else {
				return 'N/A';
			}
		}
		if (type === 'boolean') {
			return nestedValue ? 'Si' : 'No';
		} else if (type === 'date') {
			const isValidDateFormat = moment.utc(nestedValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
			if (isValidDateFormat) {
				return moment.utc(nestedValue).format('DD-MM-YYYY');
			}
			return 'N/A';
		} else if (!nestedValue) {
			return 'N/A';
		}
		return nestedValue;
	}

	//to export
	const [selectedFields, setSelectedFields] = useState([selectedFieldToExport || 'customId']);//default selectedField
	const [exportName, setExportName] = useState('');
	const todayDate = moment().format('DD-MM-YYYY');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);

	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMapExport;
		const header = selectedFields.map((field) => selectedColumnMap[field]);
		const body = data.map((item) =>
			selectedFields.map((field) => getFormattedValueExport(item, field)),
		);
		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columnsExport.length / columnsPerRow) },
		(_, rowIndex) =>
			columnsExport.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	//checkboxes column, se usa en el caso de alquileres
	async function toggleAllCheckboxes(e) {
		const allItems = frontPaginator ? currentPosts : data;
		if(e.target.checked){
			setSelectedItems(allItems);
		}else{
			setSelectedItems(selItems => selItems.filter(item => !allItems.includes(item)));
		}
	}
	

	//manage backend pagination
	// eslint-disable-next-line no-unused-vars
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(posts ? posts : 5);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);




	// Define default actions based on dataName
	const getDefaultActions = () => {
		const baseActions = {
			seeDetails: {
				handler: (item) => actionButtons?.seeDetails?.(item),
				label: 'Ver Detalles'
			},
			editHandler: {
				handler: (item) => actionButtons?.editHandler?.(item),
				label: 'Editar'
			},
			deleteHandler: {
				handler: (item) => actionButtons?.deleteHandler?.(item),
				label: 'Eliminar'
			}
		};
		return baseActions;
	};

	// Handle row action
	const handleRowAction = (actionName, item) => {
		const actions = useBasicActionButtons ? getDefaultActions(dataName) : actionButtons;
		if (actions && actions[actionName]?.handler) {
			actions[actionName].handler(item);
		}
	};

	// Check if action should be shown based on restrictions
	const shouldShowAction = (action, item) => {
		// If no restrictions defined, show the action
		if (!action.restrictions) return true;

		const { status, hasAttachment, ...otherRestrictions } = action.restrictions;
		
		// Check status restriction
		if (status && item.status !== status) return false;
		
		// Check attachment restriction
		if (hasAttachment && !item.escritura && !item.comprobante) return false;
		
		// Check any additional restrictions
		for (const [key, value] of Object.entries(otherRestrictions)) {
			if (item[key] !== value) return false;
		}
		
		return true;
	};
	const handleSelectItem = (item, e) => {
		if (e.target.checked) {
			setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
		} else {
			setSelectedItems((prevSelectedItems) => prevSelectedItems.filter(currentItem => currentItem._id !== item._id));
		}
	};
	return (
		<>
			{exportOption ? (
				<div className='container m-3'>
					<Row>
						<Col className='d-flex justify-content-end'>
							<Button
								size={'sm'}
								className='mb-1 '
								onClick={handleShow}
								style={{ backgroundColor: 'Green' }}
							>
								<FaFileExcel></FaFileExcel> Exportar
							</Button>
						</Col>
					</Row>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Exportar datos</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group as={Row} className='mb-3 align-items-center'>
								<Form.Label column md={6}>
									Nombre del archivo:
								</Form.Label>
								<Col>
									<Form.Control
										type='text'
										placeholder='Ingrese el nombre'
										value={exportName}
										onChange={(e) => setExportName(e.target.value)}
									/>
								</Col>
							</Form.Group>
							<>
								<p>Marque los campos que desea incluir</p>
								{rows.map((row, rowIndex) => (
									<Row key={rowIndex}>
										{row.map((column) => (
											<Col key={column} md={6}>
												<Form.Group
													as={Row}
													className='mb-3 align-items-center'
													controlId={`fieldsToInclude_${column}`}
												>
													<Form.Check
														className='col-8'
														type='checkbox'
														value={column}
														label={columnMapExport[column]}
														defaultChecked={column === (selectedFieldToExport || 'customId')}//default selected field, can be _id
														disabled={column === (selectedFieldToExport || 'customId')}//default selected field, can be _id
														onChange={(e) => {
															const selected = e.target.checked;
															setSelectedFields((prevSelectedFields) =>
																selected
																	? [...prevSelectedFields, column]
																	: prevSelectedFields.filter((field) => field !== column),
															);
														}}
													/>
												</Form.Group>
											</Col>
										))}
									</Row>
								))}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Cancelar
							</Button>
							<Button variant='primary' onClick={handleDownloadExcel}>
								Exportar XLS
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			) : (
				''
			)}
			<Table bordered hover responsive size='sm'>
				<thead>
					<tr>
						{showCheckboxColumn && 
							<th className='last-col'>
								<Form.Check
									type={'checkbox'}
									id='allCheckboxes'
									onClick={(e) => toggleAllCheckboxes(e)}
								></Form.Check>
							</th>
						}
						{columns.map((column) => (
							<th key={column} onClick={frontPaginator ? null : handleSort? () => handleSort(column):null}>
								{columnMap[column]}
								{column === sortColumn && (
									<span>{direction === 'desc' ? <FaSortUp /> : <FaSortDown />}</span>
								)}
							</th>
						))}
						{actionButtons && <th className='last-col text-center'></th>}
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan={5}>
								<LoadingTableBox loading={loading} />
							</td>
						</tr>
					) : error ? (
						<tr colSpan={5}>
							<td colSpan={5}>
								<MessageBox variant='danger'>{error}</MessageBox>
							</td>
						</tr>
					) : data.length === 0 ? (
						<tr colSpan={10}>
							<td colSpan={10}>
								<MessageBox variant="light">No hay resultados</MessageBox>
							</td>
						</tr>
					) : frontPaginator ? (
						currentPosts.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											checked={selectedItems.some(selectedItem => selectedItem._id === item._id)}
											onChange={(e) => handleSelectItem(item, e)}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={`${item._id}-${column}`}>
										<DynamicTableField column={column} data={item} />
									</td>
								))}
								{actionButtons && (
									<td className='actions-col'>
										<div className='actions-container'>
											<Dropdown className='dropdown-dynamic-table'>
												<Dropdown.Toggle as={IoTriangleOutline} id='dropdown-custom-1' drop='down-centered'>
												</Dropdown.Toggle>
												<Dropdown.Menu className='details-menu'>
													{Object.entries(useBasicActionButtons ? getDefaultActions(dataName) : actionButtons)
														.filter(([, action]) => shouldShowAction(action, item))
														.map(([actionName, action]) => (
															<div key={actionName} className='action'>
																<Dropdown.Item
																	onClick={() => handleRowAction(actionName, item)}
																>
																	{action.label || getDefaultLabel(actionName)}
																</Dropdown.Item>
															</div>
														))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</td>
								)}
							</tr>
						))
					) : data && !frontPaginator ? (
						data.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											checked={selectedItems.includes(item)}
											onChange={(e) => handleSelectItem(item, e)}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={`${item._id}-${column}`}>
										<DynamicTableField column={column} data={item} />
									</td>
								))}
								{actionButtons && (
									<td className='actions-col'>
										<div className='actions-container'>
											<Dropdown className='dropdown-dynamic-table'>
												<Dropdown.Toggle as={IoTriangleOutline} id='dropdown-custom-1' drop='down-centered'>
												</Dropdown.Toggle>
												<Dropdown.Menu className='details-menu'>
													{Object.entries(useBasicActionButtons ? getDefaultActions(dataName) : actionButtons)
														.filter(([, action]) => shouldShowAction(action, item))
														.map(([actionName, action]) => (
															<div key={actionName} className='action'>
																<Dropdown.Item
																	onClick={() => handleRowAction(actionName, item)}
																>
																	{action.label || getDefaultLabel(actionName)}
																</Dropdown.Item>
															</div>
														))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</td>
								)}
							</tr>
						))
					) : (
						''
					)}
				</tbody>
			</Table>
			<Pagination
				postsPerPage={postsPerPage}
				totalPosts={data.length}
				onPageChange={onPageChange}
				totalCount={count}
				currentPage={parseInt(page)}
				pageSize={pageSize}
			/>
		</>
	);
};

export default DynamicListTable;