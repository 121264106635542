import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmationModal.css';
import GridLoader from 'react-spinners/GridLoader';

/**
 * ConfirmationModal Component
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.show - Determines if the modal is visible
 * @param {function} props.handleClose - Function to call when the modal is closed
 * @param {function} props.handleConfirm - Function to call when the confirm button is clicked
 * @param {string} [props.title=''] - Title of the modal
 * @param {string} [props.description=''] - Description text of the modal
 * @param {string} [props.confirmCaption='Confirmar'] - Text to display on the confirm button
 * @param {string} [props.cancelCaption='Cancelar'] - Text to display on the cancel button
 */

function ConfirmationModal({ show, handleClose, handleConfirm, title = '', description = '', confirmCaption = 'Confirmar', cancelCaption = 'Cancelar' }) {
	const [loading, setLoading] = useState(false);

	const confirm = async () => {
		setLoading(true);
		await handleConfirm();
		setLoading(false);
		handleClose();
	};
	return (
		<Modal show={show} onHide={handleClose} animation={false} dialogClassName='confirm-modal'>
			<Modal.Header closeButton={!loading}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{<p>{description}</p>}
				{loading &&
					<GridLoader className='grid' />}
			</Modal.Body>
			<Modal.Footer>
				<Button className='cancel' disabled={loading} onClick={handleClose}>
					{cancelCaption}
				</Button>
				<Button className='confirm' disabled={loading} onClick={confirm}>
					{confirmCaption}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ConfirmationModal;