export default class PaymentMethod {
	method;
	details;
	active;
	usesPaymentGateway;
	uploadAttachment;
	chargePercent;

	constructor(method, details, active, usesPaymentGateway, uploadAttachment, chargePercent) {
		this.method = method;
		this.details = details;
		this.active = active;
		this.usesPaymentGateway = usesPaymentGateway;
		this.uploadAttachment = uploadAttachment;
		this.chargePercent = chargePercent;
	}

	static validators = {
		method: (value) => {
			if (!value || value.trim().length === 0) {
				return 'El método de pago es requerido';
			}
			if (value.toLowerCase() === 'mercadopago') {
				return 'MercadoPago está integrado por defecto';
			}
			return null;
		},
		details: (value) => {
			if (!value || value.trim().length === 0) {
				return 'Los detalles son requeridos';
			}
			return null;
		},
		chargePercent: (value) => {
			const numValue = Number(value);
			if (isNaN(numValue)) {
				return 'El porcentaje debe ser un número';
			}
			if (numValue < 0 || numValue > 100) {
				return 'El porcentaje debe estar entre 0 y 100';
			}
			return null;
		}
	};

	static formTemplate = {
		method: {
			type: 'text',
			label: 'Método',
			required: true,
			validator: PaymentMethod.validators.method
		},
		details: {
			type: 'textarea',
			label: 'Detalles',
			required: true,
			validator: PaymentMethod.validators.details
		},
		active: {
			type: 'boolean',
			label: 'Activo',
		},
		uploadAttachment: {
			type: 'boolean',
			label: 'Requiere Comprobante',
		},
		usesPaymentGateway: {
			type: 'boolean',
			label: 'Usa Pasarela de Pago',
		},
		chargePercent: {
			type: 'number',
			label: 'Porcentaje de Comisión',
			validator: PaymentMethod.validators.chargePercent,
			min: 0,
			max: 100,
			step: 0.01
		}
	};

	isValid() {
		return this.validateAll().length === 0;
	}

	validateAll() {
		const errors = [];
        
		// Check method
		const methodError = PaymentMethod.validators.method(this.method);
		if (methodError) errors.push(methodError);

		// Check details
		const detailsError = PaymentMethod.validators.details(this.details);
		if (detailsError) errors.push(detailsError);

		// Check chargePercent
		const chargePercentError = PaymentMethod.validators.chargePercent(this.chargePercent);
		if (chargePercentError) errors.push(chargePercentError);

		return errors;
	}

	static create({ method, details, active, usesPaymentGateway, uploadAttachment, chargePercent}) {
		return new PaymentMethod(method, details, active, usesPaymentGateway, uploadAttachment, chargePercent);
	}

	static createEmpty() {
		return new PaymentMethod('', '', false, false, false, 0);
	}

	static validate(paymentMethod) {
		if (!(paymentMethod instanceof PaymentMethod)) {
			paymentMethod = new PaymentMethod(
				paymentMethod.method,
				paymentMethod.details,
				paymentMethod.active,
				paymentMethod.usesPaymentGateway,
				paymentMethod.uploadAttachment,
				paymentMethod.chargePercent
			);
		}
		return paymentMethod.isValid();
	}
}