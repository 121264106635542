import axios from '../restClient';

const BASE_URI = '/product-variants';
class ProductVariantsService {
	async get(id) {
		const res = await axios.get(
			BASE_URI,
			{
				params: {
					id
				},
			},
		);
		return res;
	}
	async edit(id, countInStock, active, price) {
		const res = await axios.put(
			BASE_URI,
			{
				countInStock,
				active,
				price,
			},
			{
				params: {
					id,
				},
			},
		);
		return res;
	}
	async getAllVariantData(productId) {
		const res = await axios.get(`${BASE_URI}/product-data`, {
			params: { productId },
		});
		return res;
	}
	async uploadImage(id, formData) {
		const res = await axios.post(
			`${BASE_URI}/image?variantId=${id}`,
			formData,
			{
				headers : {
					'Content-Type': 'multipart/form-data',
				}
			},
		);
		return res;
	}
	async deleteImage(imageId) {
		const res = await axios.delete(`${BASE_URI}/image`, { params: {imageId} });
		return res;
	}
	async generateVariants(optionsWithValues, productId) {
		const res = await axios.post(`${BASE_URI}/generate-variants`, {
			optionsWithValues,
			productId,
		});
		return res;
	}
	async generateVariantsFromProduct(variants, productIds, baseProductNewName) {
		const res = await axios.post(`${BASE_URI}/pre-generated`, {
			variantsDrafts: variants,
			productIds,
			baseProductNewName
		});
		return res;
	}
	async createOption(data) {
		const res = await axios.post(BASE_URI + '/options', data);
		return res;
	}
	async createOptionValues(data) {
		const res = await axios.post(BASE_URI + '/options/values', data);
		return res;
	}
	async removeOption(optionId) {
		const res = await axios.delete(BASE_URI + `/options?id=${optionId}`);
		return res;
	}
	async removeOptionValue(id) {
		const res = await axios.delete(BASE_URI + `/options/values?id=${id}`);
		return res;
	}
	async;
}
export default new ProductVariantsService();
