/* eslint-disable semi */
import axios from '../restClient';

const BASE_URI = '/banners';
class BannersService {
	async list() {
		const res = await axios.get(BASE_URI);
		return res;
	}
	async get(id) {
		const res = await axios.get(`${BASE_URI}/${id}`);
		return res;
	}
	async listActive() {
		const res = await axios.get(BASE_URI + '/isActiveBanner');
		return res;
	}
	async edit(id, name, image, isActive) {
		const res = await axios.put(`${BASE_URI}/${id}`, {
			name,
			image,
			isActive,
		});
		return res;
	}

	async create(name, image, isActive) {
		const res = await axios.post(BASE_URI, {
			name,
			image,
			isActive,
		});
		return res;
	}
	async delete(id) {
		const res = await axios.delete(`${BASE_URI}/${id}`);
		return res;
	}
	async uploadBanner(formData) {
		const res = await axios.post(
			BASE_URI + '/uploadBannerImg',
			formData,
			{headers : {
				'Content-Type': 'multipart/form-data',
			}}
		);
		return res;
	}
}
export default new BannersService();
