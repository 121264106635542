import { Helmet } from 'react-helmet-async';
import React, {
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import LoadingBox from '../../components/LoadingBox/LoadingBox.jsx';
import MessageBox from '../../components/MessageBox/MessageBox.jsx';
import { getError, responsive } from '../../utils';
import { Store } from '../../Store';
import GoBackBtn from '../../components/GoBackButton/GoBackBtn.jsx';
import './ProductScreen.css';
import Product from '../../components/Product/Product.jsx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import PillInput from '../../components/PillInput/PillInput.jsx';
import _products from '../../services/product.service.js';
import AddToCartButton from './AddToCartButton.jsx';

const reducer = (state, action) => {
	switch (action.type) {
	case actions.FETCH_REQUEST:
		return { ...state, loading: true };
	case actions.FETCH_PRODUCT_SUCCESS:
		return {
			...state, 
			hasVariants: action.payload.hasVariants,
			categoryId: action.payload.categoryId, 
			loading: false
		};
	case actions.FETCH_PRODUCT_WITH_VARIANTS_SUCCESS:
		return {
			...state,
			options : action.payload.options,
			optionValues: (() => {
				const mappedOptions = action.payload.options?.map(option => {
					const uniqueValues = new Set();
					
					action.payload.variants?.forEach(variant => {
						const optionValue = variant.options.find(
							variantOption => variantOption.option._id === option._id
						);
						
						if (optionValue) {
							uniqueValues.add(JSON.stringify({
								_id: optionValue.value._id,
								value: optionValue.value.value
							}));
						}
					});

					return {
						_id: option._id,
						name: option.name,
						values: Array.from(uniqueValues).map(v => JSON.parse(v))
					};
				});
				
				return mappedOptions;
			})(),
			variants : action.payload.variants,
			loading: false
		};
	case actions.FETCH_DATA_FAIL:
		return { ...state, loading: false, error: action.payload };
	case actions.FETCH_SIMILPRODUCTS_REQUEST:
		return { ...state, loading: true };
	case actions.FETCH_SIMILPRODUCTS_SUCCESS:
		return { ...state, simil: action.payload, loading: false };
	case actions.FETCH_SIMILPRODUCTS_FAIL:
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};


function ProductScreen() {
	const params = useParams();
	const { slug } = params;
	const [optionsOfVariant, setOptionsOfVariant] = useState([]);
	const [loadedVariant,setLoadedVariant] = useState(null);
	const [productImage, setProductImage] = useState(null);
	const [variantImage, setVariantImage] = useState(null);
	const [product, setProduct] = useState({});
	const [variantsData, setVariantsData] = useState(null);

	const { state, dispatch: cxtDispatch } = useContext(Store);
	const { cart } = state;
	const { cartItems } = cart;
	// eslint-disable-next-line no-unused-vars
	const [item, setItem] = useState('');
	const [numOfProd, setNumOfProd] = useState(1);
	const [quantityError, setQuantityError] = useState(null);
	const [{ loading, error, options, optionValues, categoryId
		,variants, hasVariants, simil }, dispatch] = useReducer(reducer, {
		simil: [],
		hasVariants: false,
		variants : [],
		options: [],
		optionValues: [],
		loading: true,
		error: '',
		quantityError,
		categoryId: ''
	});

	//#region EFFECTS
	useEffect(() => {
		const fetchData = async () => {
			await fetchProduct();
		};
		fetchData();
	}, [slug]);

	useEffect(() => {
		const fetchData = async () => {
			if (product.hasVariants && product._id) {
				await fetchVariantsData();
			}
		};
		fetchData();
	}, [product]);

	useEffect(() => {
		const fetchData = async () => {
			if (categoryId) {
				await fetchSimil();
			}
		};
		fetchData();
	}, [categoryId]);

	useEffect(() => {
		const parsedQty = parseInt(numOfProd);
		const stock = product.hasVariants ? (loadedVariant && loadedVariant.countInStock) : product.countInStock;
		const parsedStock = parseInt(stock);
		if(parsedStock >=0 ) {
			if(parsedQty) {
				if (parsedQty <= stock) {
					setQuantityError(null);
				} else {
					setQuantityError('Ingrese una cantidad menor.');
				}
			} else {
				setQuantityError('Debe ingresar una cantidad de 1 o más.');
			}
		}
	}
	, [numOfProd]);


	//#region REQUESTS
	async function fetchProduct() {
		setLoadedVariant(null);
		setProductImage(null);
		setVariantImage(null);
		dispatch({ type: actions.FETCH_REQUEST });
		try {
			const { data: productData } = await _products.getBySlug(slug);
			setProduct(productData);
			if(!productData.hasVariants) {
				const productImg = getImageForProduct(productData);
				setProductImage(productImg);
			}
			dispatch({
				type: actions.FETCH_PRODUCT_SUCCESS,
				payload: {
					categoryId: productData.category ? productData.category._id : null,
					hasVariants: productData.hasVariants
				}
			});
		} catch (error) {
			dispatch({ type: actions.FETCH_DATA_FAIL, payload: getError(error) });
		}
	}

	async function fetchVariantsData() {
		try {
			const { data } = await _products.getWithVariants(product._id);
			setVariantsData(data);
			const firstVariant = data.variants[0];
			setLoadedVariant(firstVariant);
			// Set initial options for first variant
			if (firstVariant) {
				const initialOptions = firstVariant.options.map(opt => ({
					option: opt.option._id,
					value: opt.value._id
				}));
				setOptionsOfVariant(initialOptions);
			}
			const varImg = getImageForProduct(product, data.variants, firstVariant);
			setVariantImage(varImg);
			dispatch({
				type: actions.FETCH_PRODUCT_WITH_VARIANTS_SUCCESS, 
				payload: data
			});
		} catch (ex) {
			console.error(ex);
			dispatch({  type: actions.FETCH_DATA_FAIL, payload: getError(ex) });
		}
	}

	async function fetchSimil() {
		dispatch({ type: actions.FETCH_SIMILPRODUCTS_REQUEST});
		try {
			const {data} = await _products.getRelatedProducts(slug, categoryId);
			dispatch({
				type: actions.FETCH_SIMILPRODUCTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: actions.FETCH_SIMILPRODUCTS_FAIL,
				payload: getError(error),
			});
		}
	}
	//#endregion

	const cartHandler = async (e) => {
		setNumOfProd(e.target.value);
	};

	const addToCartHandler = async () => {
		const parsedNumOfProd = parseInt(numOfProd);
		const {hasVariants} = product;
		if (parsedNumOfProd) {
			if ((!hasVariants || !options.length) || loadedVariant) {
				try {
					let prod;
					if (hasVariants && options.length) {
						// Use cached variant data instead of making a new request
						prod = variantsData.variants.find(v => v._id === loadedVariant._id);
					} else {
						const { data } = await _products.get(product._id);
						prod = data;
					}
					const existingProduct = cartItems.find((cartItem) => cartItem._id === prod._id);
					const quantity = existingProduct ? parseInt(existingProduct.quantity) + parsedNumOfProd : parsedNumOfProd;
					let totalItemsQuantity = quantity;
					if (prod.countInStock >= totalItemsQuantity) {
						cxtDispatch({ type: 'CART_ADD_ITEM', payload: { ...prod, quantity} });
						cxtDispatch({ type: 'OPEN_CART'});
					} else {
						toast.warning('El stock no es suficiente.');
					}
				} catch(ex) {
					console.error(ex);
				}
			} else {
				toast.warning('Debe seleccionar una opción');
			}
		} else {
			toast.warning('Cantidad inválida.');
		}
	};

	const selectValueHandler = (value, optionId) => {
		// Create new options array with selected value
		const newOptionsOfVariant = [...optionsOfVariant];
		const optionIndex = newOptionsOfVariant.findIndex(opt => opt.option === optionId);
		
		if (optionIndex >= 0) {
			newOptionsOfVariant[optionIndex] = { option: optionId, value: value._id };
		} else {
			newOptionsOfVariant.push({ option: optionId, value: value._id });
		}
		
		setOptionsOfVariant(newOptionsOfVariant);

		// Find matching variant if all options are selected
		if (newOptionsOfVariant.length === options.length) {
			const matchingVariant = variants.find(variant => {
				return variant.options.every(varOpt => {
					const selectedOpt = newOptionsOfVariant.find(
						opt => opt.option === varOpt.option._id
					);
					return selectedOpt && selectedOpt.value === varOpt.value._id;
				});
			});

			if (matchingVariant) {
				setLoadedVariant(matchingVariant);
				const varImg = getImageForProduct(product, variants, matchingVariant);
				setVariantImage(varImg);
			}
		}
	};

	const createMarkup = (html) => {
		return {
			__html: DOMPurify.sanitize(html),
		};
	};
	const getImageForProduct = (product, variants = [], loadedVariant = null) => {
		const alt = product.name;
		let className = 'empty-list-image-preview';
		let src = '/images/emptyPhoto.png';
		if(product.hasVariants) {
			if(loadedVariant?.image || variants.some(v => v.image)) {
				src = loadedVariant?.image?.fileLink || variants.find(v => v.image).image.fileLink;
				className = 'prod-page-img-lg';
			}
		} else if(product.image?.fileLink) {
			src = product.image?.fileLink;
			className = 'prod-page-img-lg';
		}
		return <img
			className={className}
			src={src}
			alt={alt}
		/>;
	};
	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant="danger">{error}</MessageBox>
	) : (
		<div className="productScreenContainer">
			<Card>
				<Card.Body>
					<Row>
						<Col lg={8}>              
							<Row className="justify-content-center"> 
								{hasVariants ? variantImage || <LoadingBox /> : productImage || <LoadingBox />}
							</Row>
						</Col>
						<Col lg={4}>
							<GoBackBtn></GoBackBtn>
							<Card>
								<Card.Body>
									<Helmet>
										<title>{product?.name?.toUpperCase()}</title>
									</Helmet>
									<Row>
										{' '}
										<h1 className="font-weight-700">
											{product?.name?.toUpperCase()}
										</h1>
										<p>
											{product.active && product.countInStock > 0 ? (
												<Badge bg="success" className="p-1">
                          Disponible
												</Badge>
											) : !product.active ? (
												<Badge bg="danger">No disponible</Badge>
											) : (
												<Badge bg="danger">Sin stock</Badge>
											)}
										</p>
									</Row>
									<Row>
										{' '}
										<p>{product.brand}</p>
									</Row>
									<Row className="align-items-center d-flex ">
										<h1>{loadedVariant ? `$${loadedVariant.price}` : product.priceRange || `$${product.price}`}</h1>
									</Row>
									<hr />
									{
										product.active && hasVariants && options.length > 0 && options.map((opt) => (
											<div className="option-value-list" key={opt._id}>
												<h4>
													{opt.name}
												</h4>
												<div className="value-list">
													{
														optionValues.find(o => o._id === opt._id)?.values
															.map((v,i) => (
																<PillInput
																	onSelect = {() => selectValueHandler(v, opt._id)}
																	selected = {loadedVariant?.options?.some(opt => opt.value._id === v._id) || optionsOfVariant.some(opt => opt.value === v._id)}
																	value={v.value} key={v.value + 'optionvalue' + i}/>
															))
													}
												</div>
											</div>
										))
									}
									<AddToCartButton item={item} product={product}
										loadedVariant={loadedVariant}
										options={options}
										optionsOfVariant={optionsOfVariant}
										numOfProd={numOfProd}
										cartHandler={cartHandler}
										quantityError={quantityError}
										addToCartHandler={addToCartHandler} />
									<p>{product.description}</p>
								</Card.Body>
							</Card>
						</Col>
						{ product.htmlDescription &&  <>
							<Row className="mt-5">
								<h1>
            Descripcion:
								</h1>
							</Row>
							<Row>
								<Col>
									<div className="product-description-container"
										dangerouslySetInnerHTML={createMarkup(product.htmlDescription)}
									></div>
								</Col>
							</Row>
						</>}
					</Row>
					{simil.length != 0 ? (
						<Row>
							<h1 style={{ margin: '2.5% auto' }}>Mas productos</h1>
							<Carousel responsive={responsive} infinite={true}>
								{simil.map((product) =>
									product.active ? (
										<Col lg={10} sm={10} key={product._id}>
											<Product product={product}></Product>
										</Col>
									) : null
								)}
							</Carousel>
						</Row>
					) : (
						<Row></Row>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}
export default ProductScreen;


const actions = {
	FETCH_REQUEST: 'FETCH_REQUEST',
	FETCH_PRODUCT_WITH_VARIANTS_SUCCESS: 'FETCH_PRODUCT_WITH_VARIANTS_SUCCESS',
	FETCH_PRODUCT_SUCCESS:'FETCH_PRODUCT_SUCCESS',
	FETCH_DATA_FAIL: 'FETCH_DATA_FAIL',
	FETCH_SIMILPRODUCTS_REQUEST: 'FETCH_SIMILPRODUCTS_REQUEST',
	FETCH_SIMILPRODUCTS_FAIL: 'FETCH_SIMILPRODUCTS_FAIL',
	FETCH_SIMILPRODUCTS_SUCCESS: 'FETCH_SIMILPRODUCTS_SUCCESS'
};