import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './DeleteConfirmationModal.css';

export default function DeleteConfirmationModal({
	show,
	handleClose,
	user,
	handleDelete,
}) {
	return (
		<div>
			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-delete-user"
				aria-labelledby="modal-delete-user"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar usuario</Modal.Title>
				</Modal.Header>
				<Modal.Body>
        ¿Seguro desea eliminar el usuario?
					<br />
					<br />
					<b>{user?.name}</b> - <b>{user?.email}</b> - <b>{user?.role}</b>
				</Modal.Body>
				<Modal.Footer>
					<div className="button-user-container">
						<Button variant="secondary" onClick={handleClose} className="modal-button">
            Cancelar
						</Button>
						<Button onClick={handleDelete} className="modal-button delete-button">
            Eliminar
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
