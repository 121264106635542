import React from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./GoBackBtn.css";

export default function GoBackBtn({ className, children }) {
  const navigate = useNavigate();
  const classname = className ? className : "";
  return (
    <button className={classname + "go-back-btn"} onClick={() => navigate(-1)}>
      <BsArrowLeftCircle /> {children || "Volver"}
    </button>
  );
}
