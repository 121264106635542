import React, { useEffect, useRef, useState } from 'react';
import './FormFilter.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PillInput from '../PillInput/PillInput';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBox from '../SearchBox/SearchBox';
import { getError, getFilterUrl } from '../../utils';
import _settings from '../../services/settings.service';
import { toast } from 'react-toastify';
import { GoSearch } from 'react-icons/go';
import { GrFilter } from 'react-icons/gr';
import Dropdown from 'react-bootstrap/Dropdown';
import { CiWarning } from 'react-icons/ci';
import { FiPlus } from 'react-icons/fi';
import { PiCurrencyDollarBold } from 'react-icons/pi';
import { SCOPES } from '../../permission-utils/scopes';
import PermissionWrapper from '../../permission-utils/PermissionWrapper';



export default function FormFilter({ fields, setFields, submitFilters, setFieldsAndSubmit, showUpdatePricesModal,
	showVariantGenerationWizard
}) {

	const navigate = useNavigate();
	const { search } = useLocation();
	const [settings, setSettings] = useState(null);
	const [searchTerm, setSearchTerm] = useState(fields.query || '');
	const searchParams = new URLSearchParams(search); 
	const stockInput = useRef(null);
	useEffect(() => {
		document.addEventListener('keydown', submitStockFilter);
		fetchCompanyData();
		return () => {
			document.removeEventListener('keydown', submitStockFilter);
		};
	},[]);

	const submitStockFilter = (event) => {
		if (event.key === 'Enter' && document.activeElement === stockInput.current) {
			event.preventDefault();
			setFieldsAndSubmit({countInStock: stockInput.current.value});
		}
	};

	const fetchCompanyData = async () => {
		try {
			const { data } = await _settings.getPublicData();
			setSettings(data);
		} catch (err) {
			toast.error(getError(err));
		}
	};

	const emptyQueryField = (field) => {
		const query = getFilterUrl({...fields, [field]: ''});
		navigate(`/AdminScreen/AdminProducts/search?${query}`);
	};

	const onChangeSearchTerms = (e,field) => {
		if (field === 'query') {
			const cleanValue = e.target.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '');
			setSearchTerm(cleanValue);
		} else {
			const cleanValue = e.target.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '');
			setFields({...fields, [field]: cleanValue});
		}
	};

	const handleSearchSubmit = () => {
		setFields({...fields, query: searchTerm});
		submitFilters();
	};

	const getKeyName = (key) => keyNames[key];

	const filterStockByMin = () => {
		const query = getFilterUrl({...fields, countInStock: settings.minStockToConfirm});
		navigate(`/AdminScreen/AdminProducts/search?${query}`);
	};
	const submit = (e) => {
		e.preventDefault();
		handleSearchSubmit();
	};
	return (
		<div className='form-filter-container d-flex flex-wrap align-items-center'>
			<Form className="form-filter w-100" onSubmit={submit}>
				<Col sm={5} xs = {12} className='d-flex align-items-center flex-nowrap'>
					<SearchBox  
						submitFilters = {submitFilters} 
						className="w-90" value={searchTerm} 
						placeholder='Buscar' icon={<GoSearch onClick={handleSearchSubmit} cursor={'pointer'}/>}
						onChange={(e) => onChangeSearchTerms(e, 'query')} />
					<Dropdown>
						<Dropdown.Toggle as={GrFilter} id="dropdown-custom-1">
						</Dropdown.Toggle>
						<Dropdown.Menu className="filter-menu">
							<Dropdown.Item
								onClick={filterStockByMin}
								eventKey="1"
								className='d-flex align-items-center justify-content-center'
							>
								<CiWarning className='min-stock'/>
                Alerta de stock
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="2"
								className='d-flex stock-filter justify-content-center'
								onClick={(e) => e.stopPropagation()}
							>
								<GoSearch className='w-10' onClick={submitFilters} />
								<Form.Control
									ref={stockInput}
									className= "w-50 bg-transparent" type='number'
									value={fields.countInStock} placeholder="Stock"
									onChange={(e) => onChangeSearchTerms(e, 'countInStock')}
								/>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col sm={7} xs={12} className='actions-col'>
					<PermissionWrapper fn={'products'} scopes={[SCOPES.canCreate]}>
						<Button className='actions' onClick={() => navigate('/AdminScreen/AdminProducts/crud')}>
							<FiPlus className='me-1' />
              Agregar producto
						</Button>
					</PermissionWrapper>
					<PermissionWrapper fn={'products'} scopes={[SCOPES.canCreate]}>
						<Button className='actions' onClick={showVariantGenerationWizard}>
							<FiPlus className='me-1' />
              Generar variantes
						</Button>
					</PermissionWrapper>
					{/* <Button className='actions' >
            <VscPercentage className='me-1' />
            Aplicar descuentos
          </Button > */}
					<Button className='actions' onClick={showUpdatePricesModal}>
						<PiCurrencyDollarBold className='me-1'/>
            Modificar precios
					</Button>
				</Col>
			</Form>
			{/* <div className="apply-filters">
      <Button className="m-0" onClick={submitFilters}>
        Aplicar filtros
      </Button>
    </div> */}
			<div className="applied-filters d-flex w-100">
				{Array.from(searchParams.keys()).filter(key => fields.hasOwnProperty(key) && getKeyName(key)).map((key) => {
					return <PillInput className={'w-fit-content'} key={key} value={`${getKeyName(key)}: ${searchParams.get(key)}`} removeHandler={() => emptyQueryField(key)} />;
				})}
			</div>
		</div>
	);
}
const keyNames = {
	query: 'Búsqueda',
	countInStock: 'Stock',
	price: 'Precio',
	category: 'Categoría'
};